<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_shipping'])"></div>
        {{ $t('fba_shipping.localization_value.value') }}
        <!--        Shipping-->
      </div>
    </template>

    <template slot="body">

      <div class="custom-row pt-4">
        <div class="custom-col custom-col--16 custom-col--xl-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_skladUsaFee'])"></div>
          <InputSum
              :label="$t('fba_skladUsaFee.localization_value.value')"
              :icoType="'dollar'"
              :placeholder="'100'"
              :numeric="true"
              v-bind:class="{'ui-no-valid': FBA.validation.orderFee}"
              :errorTxt="$t(`${FBA.validationTranslate.orderFee}.localization_value.value`)"
              :error="FBA.validation.orderFee"
              v-model="FBA.data.orderFee"
              @change="(val) => FBA.setOrderFee(val)"
          />
        </div>
        <div class="custom-col custom-col--16 custom-col--xl-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_packaging'])"></div>
          <InputSum
              :label="$t('fba_packaging.localization_value.value')"
              :icoType="'dollar'"
              :placeholder="'100'"
              :numeric="true"
              v-bind:class="{'ui-no-valid': FBA.validation.packingCost}"
              :errorTxt="$t(`${FBA.validationTranslate.packingCost}.localization_value.value`)"
              :error="FBA.validation.packingCost"
              v-model="FBA.data.packingCost"
              @change="(val) => FBA.setPackingCost(val)"
          />
        </div>
        <div class="custom-col custom-col--16 custom-col--xl-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_setCost'])"></div>
          <InputSum
              :label="$t('fba_setCost.localization_value.value')"
              :icoType="'dollar'"
              :placeholder="'100'"
              :numeric="true"
              v-bind:class="{'ui-no-valid': FBA.validation.setCost}"
              :errorTxt="$t(`${FBA.validationTranslate.setCost}.localization_value.value`)"
              :error="FBA.validation.setCost"
              v-model="FBA.data.setCost"
              :disabled="true"
          />
        </div>
        <div class="custom-col custom-col--33 custom-col--xl-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_orderStatus', 'fba_statusInProgress', 'fba_statusCompleted'])"></div>
<!--              :optionsLabel="$t(`fba_translateKey.localization_value.value`)"-->
          <DefaultSelect
              :label="$t('fba_orderStatus.localization_value.value')"
              :options="optionsStatus"
              :otherValue="'translation'"
              v-bind:class="{'ui-no-valid': FBA.validation.status}"
              :errorTxt="$t(`${FBA.validationTranslate.status}.localization_value.value`)"
              :error="FBA.validation.status"
              :selected="FBA.data.status"
              @change="(val) => FBA.setStatus(val)"
          />
        </div>
      </div>

      <div class="custom-row">
        <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_shippingDate', ])"></div>
          <DatePickerDefault
              :label="$t('fba_shippingDate.localization_value.value')"
              :value="FBA.data.shippingDate"
              v-bind:class="{'ui-no-valid': FBA.validation.shippingDate}"
              :errorTxt="$t(`${FBA.validationTranslate.shippingDate}.localization_value.value`)"
              :error="FBA.validation.shippingDate"
              :disabled="!isAdmin"
          >
            <template slot="body">
              <date-picker
                  v-model="FBA.data.shippingDate"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :readonly="true"
                  :disabled="!isAdmin"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>


        <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_trackingNumber'])"></div>
          <DefaultInput
              :label="$t('fba_trackingNumber.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': FBA.validation.trackingNumber}"
              :errorTxt="$t(`${FBA.validationTranslate.trackingNumber}.localization_value.value`)"
              :error="FBA.validation.trackingNumber"
              v-model="FBA.data.trackingNumber"
          />
        </div>

        <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_shippingCost'])"></div>
          <DefaultInput
              :label="$t('fba_shippingCost.localization_value.value')"
              :type="'text'"
              :disabled="true"
              :value="getShippingCost"
          />
<!--              v-bind:class="{'ui-no-valid': FBA.validation.trackingNumber}"-->
<!--              :errorTxt="$t(`${FBA.validationTranslate.trackingNumber}.localization_value.value`)"-->
<!--              :error="FBA.validation.trackingNumber"-->
        </div>

      </div>

      <div class="custom-row">
        <div class="custom-col custom-col--66 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
          <TextareaDefault
              :label="$t('common_comment.localization_value.value')"
              v-bind:class="{'ui-no-valid': FBA.validation.comment}"
              :error="FBA.validation.comment"
              :errorTxt="$t(`${FBA.validationTranslate.comment}.localization_value.value`)"
              v-model="FBA.data.comment"
          />
        </div>

        <div class="custom-col custom-col--33 custom-col--xl-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--          <div class="admin-edit" @click="editTranslate(['invoices_Total'])"></div>-->
          <DefaultInput
              :label="$t('invoices_Total.localization_value.value')"
              :type="'text'"
              :disabled="true"
              :value="getTotalAmount"
          />
        </div>
      </div>
    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {FBA_ORDER_STATUS} from "../../../../../../../../staticData/staticVariables";
  import DatePickerDefault from "../../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import TextareaDefault from "../../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "Shipping",
    components: {
      DefaultInput,
      AccordBlock,
      InputSum,
      DefaultSelect,
      DatePickerDefault,
      DatePicker,
      TextareaDefault,
    },

    mixins: [mixinDetictingMobile],

    props: {
      FBA: Object
    },

    computed: {
      getTotalAmount() {
        return this.FBA.data.Order.data.orderOBJ?.payed_invoice?.total_amount || 0
      },
      getShippingCost() {
        return this.FBA.data.Order.data.orderOBJ?.shipping_cost || 0
      },
    },

    data() {
      return {
        optionsStatus: FBA_ORDER_STATUS,
      }
    },

  }
</script>

<style scoped lang="scss">

</style>
