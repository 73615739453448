<template>
  <div class="order-create__section">
    <AddressBlock
        v-if="FBA.data.userLabelTypes.warehouseBuyLabel === FBA.data.userLabelType"
        :FBA="FBA"
        :personal="FBA.data.Personal"
        :delivery="FBA.data.Delivery"
    />

    <FilesBlock
      :FBA="FBA"
    />

    <div class="order-create__section-label section-label"  v-if="isAdmin"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_overallDimensions'])"></div>
      {{ $t('fba_overallDimensions.localization_value.value') }}
<!--      Overall Dimensions-->
    </div>

    <DimensionsBlock
      v-if="isAdmin || FBA.data.userLabelTypes.warehouseBuyLabel === FBA.data.userLabelType"
      :FBA="FBA"
    />

    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_comment'])"></div>
      {{ $t('fba_comment.localization_value.value') }}
<!--      Comment-->
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_comment'])"></div>
        <TextareaDefault
            :label="$t('common_comment.localization_value.value')"
            v-bind:class="{'ui-no-valid': FBA.validation.comment}"
            :error="FBA.validation.comment"
            :errorTxt="$t(`${FBA.validationTranslate.comment}.localization_value.value`)"
            v-model="FBA.data.comment"
        />
      </div>
    </div>

    <template v-if="isAdmin">

      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_shippingDetails'])"></div>
        {{ $t('fba_shippingDetails.localization_value.value') }}
  <!--      Shipment Details-->
      </div>
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_shippingDate', ])"></div>
          <DatePickerDefault
              :label="$t('fba_shippingDate.localization_value.value')"
              :value="FBA.data.shippingDate"
              v-bind:class="{'ui-no-valid': FBA.validation.shippingDate}"
              :errorTxt="$t(`${FBA.validationTranslate.shippingDate}.localization_value.value`)"
              :error="FBA.validation.shippingDate"
              :disabled="!isAdmin"
          >
  <!--          :label="'Shipping Date (Optional)'"-->
            <template slot="body">
              <date-picker
                  v-model="FBA.data.shippingDate"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :readonly="true"
                  :disabled="!isAdmin"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_trackingNumber'])"></div>
          <DefaultInput
              :label="$t('fba_trackingNumber.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': FBA.validation.trackingNumber}"
              :errorTxt="$t(`${FBA.validationTranslate.trackingNumber}.localization_value.value`)"
              :error="FBA.validation.trackingNumber"
              v-model="FBA.data.trackingNumber"
              :disabled="true"
          />
        </div>
  <!--      <div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
  <!--        <DefaultInput-->
  <!--            :label="'UPC Code (Optional)'"-->
  <!--            :type="'text'"-->
  <!--            v-bind:class="{'ui-no-valid': FBA.validation.upc}"-->
  <!--            :errorTxt="$t(`${FBA.validationTranslate.upc}.localization_value.value`)"-->
  <!--            :error="FBA.validation.upc"-->
  <!--            v-model="FBA.data.upc"-->
  <!--            :disabled="!isAdmin"-->
  <!--        />-->
  <!--      </div>-->
      </div>
    </template>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {FBA_FILE_TYPE} from "../../../../../../staticData/staticVariables";
  import {FBAMixin} from "../../../../../../mixins/FBAMixins/FBAMixin";
  import DimensionsBlock from "../chunks/DimensionsBlock/DimensionsBlock";
  import FilesBlock from "../chunks/FilesBlock/FilesBlock";
  import AddressBlock
    from "@/components/modules/OrdersFBAModule/components/components/chunks/AddressBlock/AddressBlock";

  export default {
    name: "StepB",
    components:{
      AddressBlock,
      FilesBlock,
      DimensionsBlock,
      DefaultInput,
      TextareaDefault,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [FBAMixin],

    props:{
      FBA: Object,
    },

    data(){
      return{
        FBA_FILE_TYPE: FBA_FILE_TYPE,
      }
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>
