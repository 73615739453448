<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section">
          <ShippingDetails
              :FBA="FBA"
              :user="FBA.data.User"
              @recalculateFees="$emit('recalculateFees')"
          />
        </div>
        <div class="detail-cards__section">
          <Shipping
              :FBA="FBA"
          />
        </div>
        <div class="detail-cards__section">
          <Dimensions
              :FBA="FBA"
          />
        </div>
        <div class="detail-cards__section" v-if="FBA.data.userLabelTypes.warehouseBuyLabel === FBA.data.userLabelType">
          <OrderDetails
              :FBA="FBA"
          />
        </div>
        <div class="detail-cards__section">
          <Files
              :FBA="FBA"
          />
        </div>
<!--        <div class="detail-cards__section">-->
<!--          <Products-->
<!--              :FBA="FBA"-->
<!--              :products="FBA.data.Products"-->
<!--              :user="FBA.data.User"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="detail-cards__section">-->
<!--          <OrderDetails-->
<!--              :FBA="FBA"-->
<!--          />-->
<!--        </div>-->
        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_cancel'])"></div>
            <router-link :to="$store.getters.GET_PATHS.ordersFBA">
              <span class="site-link site-link--alt brown">
                          {{ $t('common_cancel.localization_value.value') }}
              </span>
            </router-link>
          </div>
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_update'])"></div>
            <MainButton
                class="sharp"
                :value="$t('common_update.localization_value.value')"
                @click.native="$emit('save', {edit: true, isAdmin: isAdmin})"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <CardLeftBlock
              :blockForAdmin="true"
              :backgroundImage="'product-admin'"
              :mobileFullWidth="true"
          >
            <template slot="body">
              <UserInfo
                :FBA="FBA"
                :user="FBA.data.User"
                :order="FBA.data.Order"
                :personal="FBA.data.Personal"
                :delivery="FBA.data.Delivery"
              />
            </template>
          </CardLeftBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import ShippingDetails from "./components/ShippingDetails/ShippingDetails";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import Shipping from "./components/Shipping/Shipping";
  import Dimensions from "./components/Dimensions/Dimensions";
  import Files from "./components/Files/Files";
  import OrderDetails
    from "@/components/modules/OrdersFBAModule/components/OrdersFBAEdit/OrdersFBAEditAdmin/components/OrderDetails/OrderDetails";
  // import Products from "./components/Products/Products";
  // import OrderDetails from "./components/OrderDetails/OrderDetails";

  export default {
    name: "OrdersFBAEditAdmin",
    components: {
      OrderDetails,
      // Products,
      Files,
      ShippingDetails,
      MainButton,
      CardLeftBlock,
      UserInfo,
      Shipping,
      Dimensions,
      // OrderDetails,
    },

    props: {
      FBA: Object,
      user: Object,
      products: Array,
    },

    data() {
      return {

      }
    },

    methods: {


    },

  }
</script>

<style scoped>

</style>
