<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_files'])"></div>
        {{ $t('fba_files.localization_value.value') }}
        <!--        Files-->
      </div>
    </template>

    <template slot="body">
      <div class="fragment pt-4">
        <FilesBlock
            :FBA="FBA"
        />
      </div>
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
import {FBAMixin} from "../../../../../../../../mixins/FBAMixins/FBAMixin";
import {FBA_FILE_TYPE} from "../../../../../../../../staticData/staticVariables";
import FilesBlock from "../../../../components/chunks/FilesBlock/FilesBlock";

export default {
  name: "Files",
  components: {
    AccordBlock,
    FilesBlock,
  },

  mixins: [FBAMixin],

  props: {
    FBA: Object,
  },

  data() {
    return {
      FBA_FILE_TYPE: FBA_FILE_TYPE,
    }
  },

  methods: {}
}
</script>

<style scoped lang="scss">

</style>
