<template>

  <div class="order-create__row custom-row">
    <div class="order-create__col custom-col custom-col--50"
         v-if="FBA.data.userLabelTypes.iHasLabel === FBA.data.userLabelType">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>
        {{ $t('fba_shippingLabel.localization_value.value') }}
        <!--          Shipping Label-->
      </div>

      <div class="order-create__row custom-row">
        <div class="custom-col"
             v-if="FBA.data.shippingLabel.data.maxCountFiles - Object.keys(FBA.data.shippingLabel.data.downloadFiles).length > 0">
          <DropZone class="drop-zone-bg"
                    :parentFiles="FBA.data.shippingLabel.data.files"
                    :multiple="false"
                    :maxCount="FBA.data.shippingLabel.data.maxCountFiles - FBA.data.shippingLabel.data.downloadFiles.length"
                    :maxSize="FBA.data.shippingLabel.data.maxSizeFiles"
                    @changeImg="(files, maxSize) => {changeFile(files, maxSize, FBA_FILE_TYPE.SHIPPING_LABEL)}"
                    :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
          />
        </div>

        <div class="custom-col"
             v-if="Object.keys(FBA.data.shippingLabel.data.downloadFiles).length > 0"
        >
          <div class="custom-row product-img-row">
            <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100">
              <DownloadFileBlock
                  v-for="(item, index) in FBA.data.shippingLabel.data.downloadFiles"
                  :key="index"
                  :file="item"
                  @removeFile="(id) => {removeFile(id, FBA_FILE_TYPE.SHIPPING_LABEL)}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__col custom-col custom-col--50">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_addProductToWarehouse'])"></div>
        {{ $t('fba_amazonFNSKU.localization_value.value') }}
        <!--          Amazon FNSKU-->
      </div>

      <div class="order-create__row custom-row">
        <div class="custom-col"
             v-if="FBA.data.amazonFNSKU.data.maxCountFiles - Object.keys(FBA.data.amazonFNSKU.data.downloadFiles).length > 0">
          <DropZone class="drop-zone-bg"
                    :parentFiles="FBA.data.amazonFNSKU.data.files"
                    :multiple="false"
                    :maxCount="FBA.data.amazonFNSKU.data.maxCountFiles - FBA.data.amazonFNSKU.data.downloadFiles.length"
                    :maxSize="FBA.data.amazonFNSKU.data.maxSizeFiles"
                    :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                    @changeImg="(files, maxSize) => {changeFile(files, maxSize, FBA_FILE_TYPE.AMAZON_FNSKU)}"
          />
        </div>
        <div class="custom-col"
             v-if="Object.keys(FBA.data.amazonFNSKU.data.downloadFiles).length > 0"
        >
          <div class="custom-row product-img-row">
            <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100">
              <DownloadFileBlock
                  v-for="(item, index) in FBA.data.amazonFNSKU.data.downloadFiles"
                  :key="index"
                  :file="item"
                  @removeFile="(id) => {removeFile(id, FBA_FILE_TYPE.AMAZON_FNSKU)}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadFileBlock from "../../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
import {FBAMixin} from "../../../../../../../mixins/FBAMixins/FBAMixin";
import DropZone from "../../../../../../coreComponents/DropZone/DropZone";
import {FBA_FILE_TYPE} from "../../../../../../../staticData/staticVariables";

export default {
  name: "FilesBlock",
  components: {
    DropZone,
    DownloadFileBlock,
  },

  mixins: [FBAMixin],

  props:{
    FBA: Object,
  },

  data(){
    return{
      FBA_FILE_TYPE: FBA_FILE_TYPE,
    }
  },
}
</script>

<style scoped>

</style>
