<template>

  <div>


<!--    <div class="order-create__row custom-row" v-if="!isLabel">-->
<!--      <div class="order-create__col custom-col"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['fbm_ChangeCarrier'])"></div>-->
<!--        <DefaultCheckbox-->
<!--            v-bind:class="{'label-red' : isAdmin}"-->
<!--            class="bold big-label"-->
<!--            :label="$t('fbm_ChangeCarrier.localization_value.value')"-->
<!--            :value="FBM.data.changeCarrier"-->
<!--            :disabled="isAdmin"-->
<!--            @input="FBM.data.changeCarrier = !FBM.data.changeCarrier"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->

    <div class="order-create__row custom-row">
<!--      v-bind:class="{'loading-cost': $store.getters.getOrdersFBMLoading}"-->
      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block small-size">
          <div class="toggle-block__type">
            <div class="toggle-block__type-list" v-if="_.has(FBA.data.shippingPartnerType, 'delivery_company_id')">
              <div class="toggle-block__type-item"
                   v-for="(item, index) in shippingCompanyData"
                   :key="index"
              >
                <RadioLabelPicture
                        name="shippingPartner"
                        :label="item.name"
                        :value="FBA.data.shippingPartnerType.delivery_company_id === item.delivery_company_id"
                        @change="changeShippingPartnerType(item)"
                >
                  <template slot="ico">
                    <img v-if="item.logo_base64" height="12" :src="item.logo_base64" alt="ico">
                  </template>
                </RadioLabelPicture>
              </div>

              <div class="toggle-block__type-item"
                   v-if="!isMobileFunc() && !isAdmin "
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'fbm_ShippingCalculating'
                ])"></div>
                <span class="site-link"
                      @click="showShippingCalculatorPopup()"
                >
                  {{$t('fbm_ShippingCalculating.localization_value.value')}}
                </span>
              </div>
            </div>

            <div v-if="FBA.data.Order.data.fileLabel"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_LabelAdmin'])"></div>
              <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + FBA.data.Order.data.fileLabel.uuid" :target="'_blank'">
                <MainButton
                    class="order-create__footer-btn-i secondary"
                    :value="$t('fbm_LabelAdmin.localization_value.value')"
                />
              </router-link>
            </div>

            <template v-if="_.has(FBA.data.shippingPartner, 'delivery_driver_service_name') &&
            FBA.data.shippingPartner.delivery_driver_service_name === 'easyPost'">
              <div v-if="FBA.data.trackingNumberRefund === 1"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_labelRefunded', 'fbm_labelRefund'])"></div>
                <TooltipTextHelperPlugin >
                  <template slot="text">
                    <StatusIcoBtn :type="'active'" class="ml-2"/>
                  </template>
                  <template slot="paragraph">
                    {{$t('fbm_labelRefunded.localization_value.value')}}
                  </template>
                </TooltipTextHelperPlugin>
              </div>

              <div v-if="FBA.data.Order.data.oldStatus.value === FBA_ORDER_STATUSES.FBA_STATUS_COMPLETED.value && FBA.data.trackingNumberRefund === 0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_labelRefunded', 'fbm_labelRefund'])"></div>
                <MainButton
                    class="order-create__footer-btn-i secondary"
                    :value="$t('fbm_labelRefund.localization_value.value')"
                    @click.native="returnLabel"
                />
              </div>
            </template>
          </div>

          <template v-if="!(FBA.data.useEasyPost && _.has(getShippingPartner, 'shipping_methods') && getShippingPartner['shipping_methods'].length === 1) ||
            (this.isCurrentUserRoleUser && FBA.data.useEasyPost )">
            <div class="toggle-block__details w-100" v-if="shippingPartner = FBA.data.shippingPartner">
              <div class="toggle-block__details-head flex-column">
                <div class="toggle-block__details-title">
                  <img v-if="FBA.data.shippingPartnerType.logo_base64" height="28" style="max-width: 100px" :src="FBA.data.shippingPartnerType.logo_base64" alt="ico">
                </div>
              </div>
              <div class="toggle-block__details-body"
              >
                <div class="custom-row">
                  <div class="custom-col flex-column-reverse mb-2"
                       v-if="getShippingPartner"
                  >
                    <template
                        v-for="(itemShippingPartner, index) in getShippingPartner['shipping_methods']">
                      <RadioDefault
                          v-if="checkShippingPartnerRequireSignature(itemShippingPartner)"
                          class="mb-2 w-auto"
                          name="fedexRadioList"
                          :key="index + 'shippingPartner'"
                          :label="itemShippingPartner.name"
                          :value="FBA.data.shippingPartner && FBA.data.shippingPartner.id === itemShippingPartner.id"
                          :cardFlex="true"
                          @mouseup.native="changeShippingPartner(itemShippingPartner)"
                      >

                        <template slot="card">
                          <div v-if="APC_CAMBRIDGE.deliveryCompanyId === FBA.data.shippingPartnerType.delivery_company_id"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['fbm_companyMailPack','fbm_companyCourierPack', 'fbm_companyParcel'])"></div>
                            <TooltipTextHelperPlugin>
                              <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                              <template slot="paragraph">
                                <span v-if="itemShippingPartner.id === 22">{{$t('fbm_companyMailPack.localization_value.value')}}</span>
                                <span v-if="itemShippingPartner.id === 23">{{$t('fbm_companyCourierPack.localization_value.value')}}</span>
                                <span v-if="itemShippingPartner.id === 24">{{$t('fbm_companyParcel.localization_value.value')}}</span>
                              </template>
                            </TooltipTextHelperPlugin>
                          </div>
                        </template>
                      </RadioDefault>
                    </template>
                  </div>
<!--                  checkSelectedUSACountry &&-->


<!--                  <div class="custom-col mb-2"-->
<!--                       v-if="FBM.data.shippingPartnerType.signature_price"-->
<!--                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                    <div class="admin-edit" @click="editTranslate([-->
<!--                      'fbm_TheSignFromRecepient',-->
<!--                      'fbm_ServiceFee',-->
<!--                      'fbm_ViewPrices'-->
<!--                    ])"></div>-->
<!--                    <DefaultCheckbox-->
<!--                            class="bold"-->
<!--                            :label="$t('fbm_TheSignFromRecepient.localization_value.value')"-->
<!--                            :value="FBM.data.signFromRecipient"-->
<!--                            @input="changeSignFromRecipient"-->
<!--                    />-->
<!--&lt;!&ndash;                            v-model="FBM.data.signFromRecipient"&ndash;&gt;-->

<!--                    <div class="amount-row light pl-4 mt-2" v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0">-->
<!--                      <template v-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX.delivery_service_code">-->
<!--                        {{$t('fbm_ServiceFeeFedex.localization_value.value')}}-->
<!--                      </template>-->
<!--                      <template v-else-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS.delivery_service_code">-->
<!--                        {{$t('fbm_ServiceFeeUsps.localization_value.value')}}-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        {{$t('fbm_ServiceFee.localization_value.value')}}-->
<!--                      </template>-->

<!--&lt;!&ndash;                      <span class="ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;                        ${{FBM.data.shippingPartnerType.signature_price}}&ndash;&gt;-->
<!--&lt;!&ndash;                      </span>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="custom-col mb-2"-->
<!--                       v-if="FBM.data.shippingPartner.possible_saturday_delivery"-->
<!--                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                    <div class="admin-edit" @click="editTranslate(['fbm_PossibleSaturdayDelivery',])"></div>-->
<!--                    <DefaultCheckbox-->
<!--                            class="bold"-->
<!--                            :label="$t('fbm_PossibleSaturdayDelivery.localization_value.value')"-->
<!--                            v-model="FBM.data.possibleSaturdayDelivery"-->
<!--                            @changeCheckbox="calculatePriceUser"-->
<!--                    />-->
<!--                  </div>-->

                  <div class="custom-col mb-0"
                       v-if="isAdminManage"
                  >
                    <div style="justify-content: flex-start;" class="toggle-block__details-bottom"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice', 'fbm_reCreateLabel'])"></div>
                      <MainButton
                              class="wfc mr-3"
                              :value="$t('fbm_CalculatePrice.localization_value.value')"
                              @click.native="calculatePriceAdmin"
                      />
<!--                      <MainButton-->
<!--                          v-if="isAdminManage && FBA.data.Order.data.fileLabel"-->
<!--                          class="wfc"-->
<!--                          :value="$t('fbm_reCreateLabel.localization_value.value')"-->
<!--                          @click.native="$emit('reCreateLabel')"-->
<!--                          v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"-->
<!--                      />-->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </template>

          <template v-if="!$store.getters.getOrdersFBMLoading">
            <div class="toggle-block__details w-100 ml-5" v-if="FBA.data.useEasyPost && this.isAdmin">

              <div class="toggle-block__details-head flex-column">
                <div class="toggle-block__details-title">
                  <img v-if="FBA.data.shippingPartnerType.logo_base64" height="22" :src="FBA.data.shippingPartnerType.logo_base64" alt="ico">
                </div>
              </div>
              <div class="toggle-block__details-body"
              >
                <div class="custom-row" v-if="FBA.data.shippingAPIPrices && Object.keys(FBA.data.shippingAPIPrices).length">
                  <div class="custom-col flex-column-reverse mb-2">
                    <RadioDefault
                            class="mb-2"
                            name="fedexRadioListRate"
                            v-for="(itemShippingAPIPrices, index) in FBA.data.shippingAPIPrices"
                            :key="index + 'shippingPrice'"
                            :label="shippingPriceRadioLabel(itemShippingAPIPrices)"
                            :value="FBA.data.shippingAPIPrice && FBA.data.shippingAPIPrice.driver_delivery_method_id === itemShippingAPIPrices.driver_delivery_method_id"
                            @click.native="changeShippingAPIPrices(itemShippingAPIPrices)"
                    />
                  </div>

                  <template v-if="_.has(FBA.data.shippingPartnerType, 'signature_price') && getShippingPartner['shipping_methods'].length === 1">
<!--                    checkSelectedUSACountry &&-->
<!--                    <div class="custom-col mb-2"-->
<!--                         v-if=" FBM.data.shippingPartnerType.signature_price"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate([-->
<!--                        'fbm_TheSignFromRecepient',-->
<!--                        'fbm_ServiceFee',-->
<!--                        'fbm_ViewPrices'-->
<!--                        ])"></div>-->
<!--                      <DefaultCheckbox-->
<!--                              class="bold"-->
<!--                              :label="$t('fbm_TheSignFromRecepient.localization_value.value')"-->
<!--                              :value="FBM.data.signFromRecipient"-->
<!--                              @input="changeSignFromRecipient"-->
<!--                      />-->
<!--                      <div class="amount-row light pl-4 mt-2" v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0">-->
<!--                        <template v-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX.delivery_service_code">-->
<!--                          {{$t('fbm_ServiceFeeFedex.localization_value.value')}}-->
<!--                        </template>-->
<!--                        <template v-else-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS.delivery_service_code">-->
<!--                          {{$t('fbm_ServiceFeeUsps.localization_value.value')}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          {{$t('fbm_ServiceFee.localization_value.value')}}-->
<!--                        </template>-->

<!--&lt;!&ndash;                        <span class="ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;                          ${{FBM.data.shippingPartnerType.signature_price}}&ndash;&gt;-->
<!--&lt;!&ndash;                        </span>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="custom-col mb-2"-->
<!--                         v-if="FBA.data.shippingPartner.possible_saturday_delivery"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['fbm_PossibleSaturdayDelivery',])"></div>-->
<!--                      <DefaultCheckbox-->
<!--                              class="bold"-->
<!--                              :label="$t('fbm_PossibleSaturdayDelivery.localization_value.value')"-->
<!--                              v-model="FBA.data.possibleSaturdayDelivery"-->
<!--                              @changeCheckbox="calculatePriceUser"-->
<!--                      />-->
<!--                    </div>-->

                    <div class="custom-col mb-0"
                         v-if="isAdminManage"
                    >
                      <div style="justify-content: flex-start;" class="toggle-block__details-bottom"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice', 'fbm_reCreateLabel'])"></div>
                        <MainButton
                            class="wfc mr-3"
                            :value="$t('fbm_CalculatePrice.localization_value.value')"
                            @click.native="calculatePriceAdmin"
                        />
<!--                        <MainButton-->
<!--                            v-if="isAdminManage && FBA.data.Order.data.fileLabel"-->
<!--                            class="wfc"-->
<!--                            :value="$t('fbm_reCreateLabel.localization_value.value')"-->
<!--                            @click.native="$emit('reCreateLabel')"-->
<!--                            v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"-->
<!--                        />-->
                      </div>
                    </div>

                  </template>

<!--                  <div class="custom-col mb-0"-->
<!--                       v-if="isAdminManage"-->
<!--                  >-->
<!--                    <div class="toggle-block__details-bottom"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice',])"></div>-->
<!--                      <MainButton-->
<!--                              class="wfc"-->
<!--                              :value="$t('fbm_CalculatePrice.localization_value.value')"-->
<!--                              @click.native="calculatePriceAdmin"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->

                </div>

                <div class="custom-row" v-if="FBA.data.shippingAPIPrices && Object.keys(FBA.data.shippingAPIPrices).length === 0">
                  <div class="custom-col">
                    <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['fbm_NoPricesAvailable'])"></div>
                      {{$t('fbm_NoPricesAvailable.localization_value.value')}}
                    </div>
                    <div v-if="isAdminManage">
                      <div class="toggle-block__details-bottom"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice',])"></div>
                        <MainButton
                            class="wfc"
                            :value="$t('fbm_CalculatePrice.localization_value.value')"
                            @click.native="calculatePriceAdmin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="FBA.data.useEasyPost">
            <div class="toggle-block__details w-100 ml-5">
              <div class="d-flex justify-content-center align-items-center">
                <DotsShadowPreloader/>
              </div>
            </div>
          </template>


        </div>
      </div>
    </div>

    <ShippingCalculatorPopup
        v-if="isModalShippingCalculatorPopup"
        @close="closeShippingCalculatorPopup"
        :fromUSA="FBA.data.userLabelTypes.warehouseBuyLabel === FBA.data.userLabelType"
        :country="FBA.data.Delivery.delivery.country"
        :dimensions="FBA.data.Dimensions"
        :city="FBA.data.Delivery.delivery.city"
        :region="FBA.data.Delivery.delivery.region"
        :zip="FBA.data.Delivery.delivery.zip"
    />

    <video style="width: 0 !important; height: 0 !important; padding: 0 !important; margin: 0 !important;"></video>
  </div>

</template>

<script>
  import RadioLabelPicture from "../../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import {
    APC_CAMBRIDGE,
    FBA_ORDER_STATUSES,
    // FBM_SHIPPING_TYPES
  } from "../../../../../../../staticData/staticVariables";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import DotsShadowPreloader
    from "../../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import {FBAMixin} from "@/mixins/FBAMixins/FBAMixin";
  import ShippingCalculatorPopup from "../../../../popups/ShippingCalculatorPopup/ShippingCalculatorPopup";

  export default {
    name: "ShippingFBA",

    components: {
      ShippingCalculatorPopup,
      TooltipTextHelperPlugin,
      StatusIcoBtn,
      TooltipBtn,
      DotsShadowPreloader,
      MainButton,
      // DefaultCheckbox,
      RadioDefault,
      RadioLabelPicture
    },

    mixins: [mixinDetictingMobile, FBAMixin],

    props: {
      FBA: Object,
      shippingCompanyData: Array,
      reloadKey: Number,
      isAdminManage: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      // getShippingPartner(newVal) {
      //   if(newVal && this.isLabel){
      //     this.getShippingPrices(this.isAdminManage)
      //   }
      // },

      reloadKey(newVal){
        if(newVal){
          this.getShippingPrices(this.isAdminManage)
        }
      }
    },

    computed: {
      getShippingPartner(){
        let partner = this._.find(this.shippingCompanyData, {delivery_company_id: this.FBA.data.shippingPartnerType.delivery_company_id})

        if(!partner) {
          partner = this._.first(this.shippingCompanyData)
          this.initShippingCompanies()
        }

        return partner
      },

    },


    data() {
      return {
        // FBM_SHIPPING_TYPES: FBM_SHIPPING_TYPES,

        APC_CAMBRIDGE: APC_CAMBRIDGE,
        FBA_ORDER_STATUSES: FBA_ORDER_STATUSES,
        isModalShippingCalculatorPopup: false,
      }
    },

    mounted() {
      /**
       * if label || has shipping API Prices (for admin manage)
       */
      if(this.FBA.data.shippingAPIPrices && Object.keys(this.FBA.data.shippingAPIPrices).length){
        this.FBA.data.useEasyPost = true
      }

      this.initShippingCompanies(this.isAdminManage)

    },

    methods: {

      /**
       * initialize Shipping Companies
       */
      initShippingCompanies(adminManage) {
        let shippingCompanies = this.FBA.data.shippingCompanies

        /**
         * if dont choose partner set first partnerType and method
         */

        if(shippingCompanies.length > 0){
          let partner = this.FBA.data.shippingPartner

          let findMethod = false
          shippingCompanies.map(company => {
            if(partner && this._.find(company.shipping_methods, {id: partner.id})){
              findMethod = true
            }
          })

          if(findMethod){
            // if(!adminManage && !show){
            //   this.getShippingPrices()
            // }
            return
          }


          this.FBA.setShippingPartnerType(shippingCompanies[0])
          this.FBA.setShippingPartner(this._.first(this._.first(shippingCompanies)['shipping_methods']))
          if((adminManage) && Object.keys(this.FBA.getShippingAPIPrice()).length === 0){
            console.log(454545);
            this.getShippingPrices()
          }
        }
      },





      checkShippingPartnerRequireSignature(itemShippingPartner) {
        console.log(itemShippingPartner);
        return true
        // if(!this._.has(itemShippingPartner, 'requaire_signature')) return true
        //
        // if(this.FBA.data.signFromRecipient && itemShippingPartner.requaire_signature) return true
        //
        // if(!this.FBA.data.signFromRecipient && !itemShippingPartner.requaire_signature) return true
        //
        // return false
      },

      // changeSignFromRecipient() {
      //   this.FBA.data.signFromRecipient = !this.FBA.data.signFromRecipient
      //
      //   if(this.FBA.data.useEasyPost && (this.isAdminManage)){
      //     this.getShippingPrices(this.isAdminManage)
      //     return
      //   }
      //
      //   if(Number(this.FBA.data.shippingPartnerType.signature_price) === 0) {
      //     if(!this.FBA.data.signFromRecipient && this.FBA.data.shippingPartner?.requaire_signature) {
      //       switch (this.FBA.data.shippingPartner.id) {
      //         case 45:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 41}))
      //           break
      //         case 46:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 42}))
      //           break
      //         case 47:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 43}))
      //           break
      //         case 48:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 44}))
      //           break
      //       }
      //     }
      //     if(this.FBA.data.signFromRecipient && !this.FBA.data.shippingPartner?.requaire_signature) {
      //       switch (this.FBA.data.shippingPartner.id) {
      //         case 41:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 45}))
      //           break
      //         case 42:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 46}))
      //           break
      //         case 43:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 47}))
      //           break
      //         case 44:
      //           this.changeShippingPartner(this._.find(this.getShippingPartner['shipping_methods'], {id: 48}))
      //           break
      //       }
      //     }
      //   }
      // },

      calculatePriceAdmin() {
        this.FBA.data.useEasyPost = true
        this.getShippingPrices(this.isAdminManage)

        // Take a photo by web camera
        // this.takePhoto()
      },

      calculatePriceUser() {
        this.getShippingPrices(this.isAdminManage)
      },

      shippingPriceRadioLabel(itemShippingAPIPrices) {
        let label = itemShippingAPIPrices.name + ' $' + itemShippingAPIPrices.shipmentCost + ' ' +
          `${itemShippingAPIPrices.warning_message ? itemShippingAPIPrices.warning_message : ''}`
        if(parseFloat(itemShippingAPIPrices.insuranceCost) > 0){
          label += ' insurance $' + itemShippingAPIPrices.insuranceCost
        }
        return label
      },

      changeShippingPartnerType(val){
        this.FBA.setShippingPartnerType(val)
        this.changeShippingAPIPrices({})
        this.changeShippingPartner(this._.first(val['shipping_methods']))
      },

      changeShippingPartner(val){
        this.FBA.setShippingPartner(val)

        if(this.FBA.data.useEasyPost && (this.isAdmin)){
          this.getShippingPrices(this.isAdminManage)
        }
      },

      changeShippingAPIPrices(val){
        this.FBA.setShippingAPIPrice(val)
      },

      addressTypeeChange(){
        console.log('addressTypeeChange');
      },

      returnLabel() {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let data = {
            "order_fbm_id" : this.FBA.data.Order.data.id
          }

          this.$store.dispatch('FBMLabelRefund', data).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      showShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup = true;
      },

      closeShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup= false
      },
    }

  }
</script>

<style scoped>
.loading-cost {
  pointer-events: none;
}

.big-label >>> .default-checkbox__label {
  font-size: 16px;
  line-height: 15px;
}

.label-red >>> .default-checkbox__label {
  color: #ff0c0c;
}
</style>
