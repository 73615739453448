<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_addProduct'])"></div>
        {{ $t(`fba_addProduct.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <SelectProductBlock
            v-for="(item, index) in productsDefault"
            :key="index+'product'"
            :item="item"
            :price="false"
            :description="false"
            :userId="parseInt(user.userId)"
            :productLabel="'name_for_fba'"
            :largeCountColumn="true"
            :byStorageId="FBA.data.Order.data.warehouse.id"
            @removeProduct="FBA.removeProduct(productsDefault, index)"
            @changeProductName="changeProductName"
            @changeProductQuantity="changeProductQuantity"
        />
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--83 custom-col--md-100">
            <div class="add-product-btn-wrap mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="FBA.addProduct(productsDefault)">
              + {{$t('common_AddProduct.localization_value.value')}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_addProducts',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            :value="$t(`common_addProducts.localization_value.value`)"
            @click.native="addProduct"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";
import SelectProductBlock from "../../../../coreComponents/SelectProductBlock/SelectProductBlock";
import {productForCellMixin} from "@/mixins/productMixins/productForCellMixin";

export default {
  name: "ProductsPopup",
  components: {
    Modal,
    MainButton,
    SelectProductBlock,
    // DefaultInput
  },

  mixins: [
    FBAMixin,
    productForCellMixin
  ],

  props: {
    FBA: Object,
    user: Object,
    productsDefault: Array,
  },

  data() {
    return {

    }
  },

  mounted() {
    this.FBA.setProductsDefault('')
  },

  methods: {
    addProduct(){
      if(!this.FBA.productsDefaultValidation()) return
      this.FBA.data.productsDefault.map((item) => {
        let newId = this.FBA.addProductForCells(item)
        this.getCellsForUser(this.FBA.data.productsForCellArray[newId], this.FBA.data.User.getUserId(), this.FBA.data?.Order.data.warehouse?.id)
      })
      this.$emit('close')
    }
  },
}
</script>

<style scoped>

</style>
