<template>
  <div class="fragment order-admin-products">



    <div class="custom-row">
      <div class="custom-col" v-if="FBA.data.productsForCellArray.length > 0">

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount','fbm_ProductName','fba_Cells'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th style="width: 20px;"></th>
              <th style="width: 100px;">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
              <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('fba_Cells.localization_value.value') }}</th>
              <th style="width: 120px;"></th>
            </tr>
            </thead>
            <tbody>

            <tr
                v-for="(item, index) in FBA.data.productsForCellArray"
                :key="index"
            >
              <td>
                <ProductCheckedBlock
                        :item="item"
                        @changeProductChecked="(val) => changeProductChecked(val, item)"
                />
              </td>
              <td v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                {{item.data.scannedCount}} / {{item.data.productCount}}
              </td>
              <td>
                <div class="table-row ellipsis max-200">
                  <ValueHelper
                          :value="item.data.Product"
                          :deep="'product.productObject.name_for_fba'"
                  />
                </div>
              </td>

              <td>
                <template v-if="cells = getCellsInTable(item)">
                  {{cells.text}}
                  <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                </template>

              </td>
              <td>
                <div class="manage-block">
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      class="mr-3"
                      @click.native="openProductForCellPopup(index)"
                  />
                  <LinkButton
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="removeProductForCells(FBA.data.productsForCellArray, index)"
                  />
                </div>

              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in FBA.data.productsForCellArray"
                 :key="index"
            >
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__head">
                  <ProductCheckedBlock
                          :item="item"
                          @changeProductChecked="(val) => changeProductChecked(val, item)"
                  />
                  <ValueHelper
                          :value="item.data.Product"
                          :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('productForCell_scannedCount.localization_value.value')}}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{item.data.scannedCount}} / {{item.data.productCount}}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                              :value="item.data.Product"
                              :deep="'product.productObject.name_for_fba'"
                      />
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fba_Cells.localization_value.value') }}
                    <span>
                      <template v-if="cells = getCellsInTable(item)">
                        {{cells.text}}
                        <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                      </template>
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col d-flex align-items-center">
                    {{ $t('common_manage.localization_value.value') }}
                    <div class="ml-2 d-flex">
                      <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              class="mr-3"
                              @click.native="openProductForCellPopup(index)"
                      />
                      <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeProductForCells(FBA.data.productsForCellArray, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('common_AddProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openProductPopup"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <!-- ****  ADD NEW PRODUCT **** -->
    <ProductsPopup
      v-if="isModalProductsPopup"
      :FBA="FBA"
      :user="user"
      :productsDefault="FBA.data.productsDefault"
      @close="changeProductsPopup(false)"
    />

    <!-- **** MANAGE CELLS **** -->
    <ProductForCellPopup
        v-if="isModalProductForCellPopup"
        :user="user"
        :price="false"
        :count="false"
        :description="false"
        :hsCode="false"
        :productForCell="FBA.data.ProductForCell"
        :productForCellItem="FBA.data.productsForCellArray[setIndex]"
        :productsForCellArray="FBA.data.productsForCellArray"
        :arrayIndex="setIndex"
        :damaged="false"
        @close="changeProductForCellPopup(false)"
    />
  </div>
</template>

<script>
import {FBAMixin} from "../../../../../../../../mixins/FBAMixins/FBAMixin";
import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
import PlusAccentIconSVG from "../../../../../../../../../public/img/UI-group/plus-accent-icon.svg?inline";
import ProductsPopup from "../../../../../popups/ProductsPopup/ProductsPopup";
import ProductForCellPopup from "../../../../../../../coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import {productForCellMixin} from "../../../../../../../../mixins/productMixins/productForCellMixin";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import ProductCheckedBlock from "../../../../components/chunks/ProductCheckedBlock/ProductCheckedBlock";

export default {
  name: "ProductsAdminBlock",
  components: {
    ProductCheckedBlock,
    ValueHelper,
    LinkButton,
    MainButton,
    PlusAccentIconSVG,
    ProductsPopup,
    ProductForCellPopup,
  },

  mixins: [
    FBAMixin,
    mixinDetictingMobile,
    productForCellMixin,
  ],

  props: {
    FBA: Object,
    user: Object,
    products: Array,
  },

  data(){
    return{
      isModalProductsPopup: false,
      isModalProductForCellPopup: false,

      setIndex: -1,
    }
  },

  methods: {



    changeProductsPopup(open){
      this.isModalProductsPopup = open
    },
    changeProductForCellPopup(open){
      this.isModalProductForCellPopup = open
      if(!open){
        this.$emit('recalculateFees')
      }
    },

    openProductPopup(){
      this.changeProductsPopup(true)
    },

    removeProductForCells(array, index){
      this.FBA.removeProductForCells(array, index)
    },

  }
}
</script>

<style scoped lang="scss">
  @import "../../../../../../../../scss/colors";

  .order-admin-products{
    .red-color{
      color: $red
    }
    .green-color{
      color: $green
    }
  }

</style>
