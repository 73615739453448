<template>
  <div class="fragment">
    <OrdersFBATableContent
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
        @downloadFiles="downloadFiles"
    />
  </div>
</template>

<script>
import OrdersFBATableContent from "./OrdersFBATableContent/OrdersFBATableContent.vue";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";

export default {
  name: "OrdersFBATable",
  components: {
    OrdersFBATableContent,
  },

  mixins: [routeFilter, FBAMixin],

  data() {
    return {
      forPage: this.$store.getters.getOrdersFBAForPage,
      page: 1,
      onePage: 1,
      filterType: '',
      filterDate: [],
      filterShippedDate: [],
      filterWarehouseId: [],
      filterUserName: '',
      filterUserId: '',
      filterId: '',
      filterTrackingNumber: '',
      filterStatus: '',
      countFilterParams: 0,
      filterGetParams: {},
    }
  },

  mounted() {
    this.filter()
  },

  methods: {

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
        date: this.$route.query.date,
        sentDate: this.$route.query.sentDate,
        shippedDate: this.$route.query.shippedDate,
        warehouseId: this.$route.query.warehouseId,
        userName: this.$route.query.userName,
        userId: this.$route.query.userId,

        id: this.$route.query.id,
        trackingNumber: this.$route.query.trackingNumber,
        status: this.$route.query.status,
      }
    },

    filter(next = false) {
      this.getFilterParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      if (next) this.$store.commit('setNextOrdersFBAPage', true)
      this.$store.dispatch('fetchOrdersFBA', url).then(() => {
        this.$store.commit('setNextOrdersFBAPage', false)
      })


      this.checkCountFilter(['user'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

      this.generateFilterQueryParams(
          myQuery,
          {
            filterUserId: 'userId',
            filterId: 'orderId',
            filterTrackingNumber: 'trackingNumber',
            filterStatus: 'status',
            filterWarehouseId: 'storage_id',
          },
      )

      if (this.filterDate.length > 0) {
        let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
        myQuery.whereIn(
            'between_created_at',
            date
        )
      }

      if (this.filterShippedDate.length > 0) {
        let deliveryDate = this.generateDateForFilter(this.filterShippedDate, 'YYYY-MM-DD')
        myQuery.whereIn(
            'between_delivery_date',
            deliveryDate
        )
      }

      return this.generateGetParamsFromQuery(myQuery, forPage, page)


    },

  },
}
</script>

<style scoped>

</style>
