<template>
  <div class="order-create__section">
    <ShippingFBA
        :reloadKey="reloadKey"
        v-if="FBA.data.shippingCompanies.length > 0"
        :FBA="FBA"
        :isAdminManage="false"
        :shippingCompanyData="FBA.data.shippingCompanies"
    />
    <span v-else-if="!$store.getters.getOrdersFBMLoading" class="color-red">Shipping company in choose country from warehouse not found</span>
  </div>
</template>

<script>
import ShippingFBA from "@/components/modules/OrdersFBAModule/components/components/chunks/ShippingFBA/ShippingFBA";
import {FBAMixin} from "@/mixins/FBAMixins/FBAMixin";

export default {

  name: "StepC",
  components: {
    ShippingFBA
  },

  mixins: [FBAMixin],

  props: {
    FBA: Object,
  },

  watch: {
    /**
     * If load user and not admin or order type Edit
     */
    orderUser(newVal) {
      if(newVal !== '' && (!this.isAdmin || this.$route.params.id)){
        this.getShippingData().then(() => {
          this.getShippingPrices()
        })
      }
    },

    /**
     * If change or choose Country
     */
    selectedCountry() {
      this.getShippingData().then(() => {
        this.getShippingPrices()
      })
    },
  },

  computed: {
    orderUser(){
      return this.FBA.data.User.userId
    }
  },

  data() {
    return {
      reloadKey: -1,
    }
  },

  mounted() {
    this.checkShippingCompany()
  },

  methods: {



  }

}
</script>

<style scoped>

</style>