<template>
  <AccordBlock>
    <template slot="header">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_shippingDetails'])"></div>
        {{ $t('fba_shippingDetails.localization_value.value') }}
<!--        Shipping Details-->
      </div>
    </template>

    <template slot="body">




      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_scanner'])"></div>
        {{ $t('fba_scanner.localization_value.value') }}
      </div>

      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_scannerHere'])"></div>
          <div class="section-label">
            <DefaultInput
                :label="$t('fba_scannerHere.localization_value.value')"
                :type="'text'"
                v-model="scan"
                @change="changeScanner"
            />

          </div>
        </div>
        <div>
          <div class="custom-col mb-0" style="margin-top: 10px;">
            <div class="d-flex">
              <RadioDefault
                  v-if="FBA.data.Order.data.warehouse"
                  class="white-space-line width-initial mr-3"
                  :label="FBA.data.Order.data.warehouse.name"
                  :name="'warehouse'"
                  :value="true"
                  :disabled="true"
              />
            </div>
          </div>
        </div>
      </div>


      <ProductsAdminBlock
          :FBA="FBA"
          :user="user"
          :products="FBA.data.Products"
          @recalculateFees="$emit('recalculateFees')"
      />

      <SetBlock
          :FBA="FBA"
          :sets="FBA.data.sets"
          :user="user"
          @recalculateFees="$emit('recalculateFees')"
      />

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  // import DatePickerDefault from "../../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  // import DatePicker from 'vue2-datepicker'
  // import TextareaDefault from "../../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {FBAMixin} from "../../../../../../../../mixins/FBAMixins/FBAMixin";
  import SetBlock from "../../../../components/chunks/SetBlock/SetBlock";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ProductsAdminBlock from "../ProductsAdminBlock/ProductsAdminBlock";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
    name: "ShippingDetails",
    components: {
      RadioDefault,
      AccordBlock,
      // DatePickerDefault,
      // DatePicker,
      // TextareaDefault,
      SetBlock,
      DefaultInput,
      ProductsAdminBlock,
    },

    mixins: [mixinDetictingMobile, FBAMixin],

    props: {
      FBA: Object,
      user: Object,
    },

    data() {
      return {
        scan: '',
      }
    },

    methods: {
      changeScanner(val){
        let added = false
        if(val.length > 0){
          this.FBA.data.productsForCellArray.map(item => {
            if(added || item.data.scannedCount === item.data.productCount) return
            if(item.data.Product.product.productObject.upc_code === val){
              item.addScannedProduct()
              added = true
            }
          })

          this.FBA.data.sets.map(itemSet => {
            itemSet.data.productsSet.map(item => {
              if(added) return
              if(item.data.Product.product.productObject.upc_code === val){
                item.addScannedProduct()
                added = true
              }
            })
          })
        }

        this.scan = ''
      }
    }

  }
</script>

<style scoped lang="scss">

</style>
