<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_order'])"></div>
      <CardLeftBlock
              :name="$t('fba_order.localization_value.value')"
              :value="`#${FBA.data.Order.data.id}`"
              :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            {{ FBA.data.createdAt | moment("DD MMM, YYYY") }}
          </div>

          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && FBA.data.Order.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('fba', FBA.data.Order.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.ordersFBA" class="order-create__head-back">
                  <LinkBack
                          :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-gray"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_orderFba'])"></div>
              {{ $t('fba_orderFba.localization_value.value') }}
            </div>

          </div>
        </template>

        <template slot="body">
          <StepFinal
                  :FBA="FBA"
                  :showDimensions="true"
                  :showType="true"
          />
        </template>

        <template slot="footer">
          <div class="order-create__footer"
               v-if="FBA.data.status.id && FBA.data.status.id !== FBA_ORDER_STATUSES.FBA_STATUS_COMPLETED.id"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_delete'])"></div>
            <div class="order-create__footer-link">
              <span class="site-link site-link--alt"
                    @click="removeItem(FBA.data.Order.data.id, true)"
              >
                {{ $t('common_delete.localization_value.value') }}
              </span>
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack.vue";
  import StepFinal from "../components/StepFinal/StepFinal";
  import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";
  import {FBA} from "../../models/OrderFBA";
  import {FBA_FILE_TYPE, FBA_ORDER_STATUSES} from "../../../../../staticData/staticVariables";

  export default {
    name: "OrderFBAShow",

    components: {
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      StepFinal,
    },

    mixins: [
      FBAMixin
    ],

    watch: {
      loadUserAuthorizedData: function () {
        this.getUserAuthorizedData()
        this.initOrder()
      },
    },

    props: {},

    data() {
      return {
        FBA: new FBA(),
        orderLoading: false,
        FBA_FILE_TYPE: FBA_FILE_TYPE,
        FBA_ORDER_STATUSES: FBA_ORDER_STATUSES,
      }
    },

    mounted() {
      let orderId = this.$route.params.id

      /**
       * Set OrderId
       */
      this.FBA.setOrderId(orderId)

      if (this.loadUserAuthorizedData) {
        this.initOrder()
      }

    },

    methods: {
      initOrder() {
        this.$store.dispatch('getOrderFBA', this.FBA.data.Order.getId()).then(response => {
          let order = this.getRespData(response)
          this.orderLoading = true

          /**
           * Set FBA
           */
          this.setFiles(order)
          this.FBA.setItem(order, this.isAdmin)

          // /**
          //  * Set FBM Proform Products
          //  */
          // this.FBM.data.ProformProducts.map((itemProduct, index) => {
          //   this.initialProformSelectOptions({
          //     val: itemProduct.product.proformData.proformCategory,
          //     item: itemProduct
          //   }).then(() => {
          //     this.loadedProformOptions = index
          //   })
          // })
        }).catch(error => this.createErrorLog(error))
      },

      setFiles(order) {
        if (order['shipping_label'].length > 0) {
          this.getFileFromServerFc(order['shipping_label'], FBA_FILE_TYPE.SHIPPING_LABEL)
        }
        if (order['amazon_fnsku'].length > 0) {
          this.getFileFromServerFc(order['amazon_fnsku'], FBA_FILE_TYPE.AMAZON_FNSKU)
        }
      },

      getFileFromServerFc(order, type = false) {
        let file = this._.first(order)
        this.$store.dispatch('getFileFromServer', file.id)
          .then((response) => {
            let dataFile = response
            let fileBase64 = dataFile
            Object.keys(dataFile).map(item => {
              dataFile[item] = {
                id: item,
                type: file.mime_type,
                uuid: file.uuid,
                base64: fileBase64[item]
              }
            })
            this.FBA.setFiles(dataFile, type)
          }).catch(error => this.createErrorLog(error))
      },

      getUserAuthorizedData() {

      },

    }
  }
</script>

<style lang="scss">

  .product-progress-steps {
    max-width: 625px;
  }

</style>
