import {checkValid} from "./functions/ModelsValidate";
import {ProductForCell} from "./ProductForCell";

export function ProductSet() {
  this.checkValid = checkValid

  this.data = {
    skuSet: '',
    productsSet: []
  }


  this.validation = {
    skuSet: false,
  }

  this.validationTranslate = {
    skuSet: '',
  }

  this.validationTxt = {
    skuSet: false,
  }

  /**
   * Getters
   */

  this.getSkuSet = () => {
    return this.data.skuSet
  }
  this.getSetProducts = () => {
    return this.data.productsSet
  }

  /**
   * Setters
   */

  this.setSkuSet = function (val) {
    this.data.skuSet = val
  }
  this.setSetProducts = function (val) {
    this.data.productsSet = val
  }


}

/**
 * GLOBAL GETTERS
 */

ProductSet.prototype.getProductSet = function () {
  return this.data
}


/**
 * GLOBAL SETTERS
 */


/**
 * FUNCTIONS
 */

ProductSet.prototype.actualizeProductForCellCounts = function () {
  this.data.productsSet.map(item => {
    item.copyProductCountInProductForCellProductCount()
  })
}

ProductSet.prototype.addNewProduct = function (product) {
  if(product){
    this.data.productsSet.push(product)
    return this.data.productsSet.length - 1
  }
  this.data.productsSet.push(new ProductForCell())
  return this.data.productsSet.length - 1
}


/**
 * VALIDATIONS
 */

ProductSet.prototype.productsInSetValidation = function () {
  let valid = true

  let validationItems = {
    skuSet: this.data.skuSet
  }

  let validationOptions = {
    skuSet: {type: ['empty']},
  }

  this.data.productsSet.map(item => {
    if (!item.data.Product.productValidate({productObject: true, count: true}))
      valid = false
  })

  return (this.checkValid(validationItems, validationOptions) && valid)
}

/**
 * PREPARE DATA
 */


