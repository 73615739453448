import { render, staticRenderFns } from "./ProductsPopup.vue?vue&type=template&id=62926a43&scoped=true"
import script from "./ProductsPopup.vue?vue&type=script&lang=js"
export * from "./ProductsPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62926a43",
  null
  
)

export default component.exports