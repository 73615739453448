<template>
  <div class="fragment">
    <OrdersFBACreationUser
        :FBA="FBA"
        @create="save"
        @changeUser="changeUser"
    />
  </div>
</template>

<script>
  import OrdersFBACreationUser from "./OrdersFBACreationUser/OrdersFBACreationUser";
  import {FBA} from "../../models/OrderFBA";
  import {FBAMixin} from "../../../../../mixins/FBAMixins/FBAMixin";

  export default {
    name: "OrdersFBACreation",
    components: {
      OrdersFBACreationUser,
    },

    mixins:[
      FBAMixin
    ],

    watch: {
      loadUserRoleAndAuthorizedData: function (val) {
        if(val){
          this.getUserData()
        }
      },
    },

    data(){
      return{
        FBA: new FBA,
      }
    },

    created() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }

      /**
       * initial first product in array
       */
      this.FBA.addProductForCells()
    },

    methods: {
      getUserData() {
        if(this.isCurrentUserRoleUser){
          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.FBA.data.User.setUser(userData)
        }
      },
    }

  }
</script>

<style scoped>

</style>
