<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['product_BackProducts'])"></div>
                <router-link :to="$store.getters.GET_PATHS.ordersFBA" class="order-create__head-back">
                  <LinkBack
                      :value="$t('product_BackProducts.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-gray"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['product_BackProducts'])"></div>
              {{ $t('fba_newFbaOrder.localization_value.value') }}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['product_BackProducts'])"></div>
              {{ $t('fba_pleaseFillOut.localization_value.value') }}
            </div>

<!--            product-progress-steps-->
            <div class="order-create__head-steps"
                 v-if="$store.getters.getIsAdminRights === 'user'"
            >
              <ProgressSteps
                  :options="progressStepsSetting"
                  :step="activeStep"
                  @changeStep="stepChange"
                  @nextPageHandler="nextPageHandler"
              />
            </div>
          </div>
        </template>

        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="FBA.data.User.validation.user"
                    :validationTxtUser="FBA.data.User.validationTxt.user"
                    :validationTranslateUser="FBA.data.User.validationTranslate.user"
                    :userSelected="FBA.data.User.user"
                    @changeUser="(user) => $emit('changeUser', user)"
                    :typeSelect="'users'"
                    :userEmail="true"
                />
              </div>
            </div>
          </div>

          <StepA v-if="activeStep === 1 || isAdmin"
                    :FBA="FBA"
                    :user="FBA.data.User"
          />
          <StepB v-if="activeStep === 2 || isAdmin"
                           :FBA="FBA"
          />
          <StepC v-if="(activeStep === 3 && isWarehouseBuyLabel) || isAdmin"
                           :FBA="FBA"
          />
          <StepFinal v-if="(activeStep === 3 && !isWarehouseBuyLabel) || (activeStep === 4 && isWarehouseBuyLabel)"
                        :FBA="FBA"
                     :showDimensions="isWarehouseBuyLabel"
          />

        </template>


        <template slot="footer">
          <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_cancel', 'common_Previous'])"></div>
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.ordersFBA"
                           class="site-link site-link--alt"
                           v-if="activeStep === 1"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep !== 1"
              >
                 {{ $t('common_Previous.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate([
                       'common_SaveInDrafts',
                      'common_create'
                   ])"></div>
<!--              <MainButton-->
<!--                  class="order-create__footer-btn-i secondary"-->
<!--                  v-if="activeStep == 3 || isAdmin"-->
<!--                  :value="$t('common_SaveInDrafts.localization_value.value')"-->
<!--                  :tooltip="true"-->
<!--                  @click.native="$emit('create', draft = true)"-->
<!--              >-->
<!--                &lt;!&ndash;                v-bind:class="{'disabled-btn' : $store.getters.getBelPoshtaBtn}"&ndash;&gt;-->
<!--                <template slot="tooltip">-->
<!--                  <p>-->
<!--                    <b>{{ $t('common_drafts.localization_value.value') }}</b>-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    {{ $t('common_savingInDrafts.localization_value.value') }}-->
<!--                  </p>-->
<!--                </template>-->
<!--              </MainButton>-->


              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_Next.localization_value.value')"
                  @click.native="nextPageHandler()"
                  v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"
              />

              <MainButton
                  v-else
                  class="order-create__footer-btn-i"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('create', {isAdmin: isAdmin, createForUser: !isAdmin})"
                  v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBABtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
import StepA from "../../components/StepA/StepA";
import StepB from "../../components/StepB/StepB";
import StepFinal from "../../components/StepFinal/StepFinal";
import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
import {FBAMixin} from "../../../../../../mixins/FBAMixins/FBAMixin";
import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
import StepC from "@/components/modules/OrdersFBAModule/components/components/StepC/StepC";

export default {
  name: "OrdersFBACreationUser",

  components: {
    StepC,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
    ProgressSteps,
    StepA,
    StepB,
    StepFinal,
    UserSelect,
  },

  mixins: [
    tabsMixins, FBAMixin
  ],

  props: {
    FBA: Object,
  },

  watch: {
    getLabelType() {
      this.checkProgressStepsSetting()
    },
  },

  computed: {
    getLabelType() {
      return this.FBA.data.userLabelType
    },
  },

  data() {
    return {
      name: '',
      name2: '',
      valueQuantity: '',
      valueSum: '',
      valueSumDisable: '',

      progressStepsSetting: [
        this.$t('fba_productsStep.localization_value.value'),
        this.$t('fba_shippingDetailsStep.localization_value.value'),
        this.$t('fba_shipping.localization_value.value'),
        this.$t('fba_confirmationStep.localization_value.value'),
      ],
      activeStep: 1,

      files: [],
      showingUpc: false,

      options: [{}],
    }
  },

  mounted() {
    this.checkProgressStepsSetting()
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

.product-progress-steps {
  max-width: 625px;
}

</style>
