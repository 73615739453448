<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_calcTitle'])"></div>
        {{$t('fbm_calcTitle.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <FromWarehouseBlock
          :calculator="calculator"
          :createData="createData"
        />
        <div class="custom-row">
          <div class="custom-col custom-col--25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_weightKg'])"></div>
            <DefaultInput
                :label="$t('common_weightKg.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.weight}"
                :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
                :error="calculator.data.Dimensions.validation.weight"
                :disabled="$store.getters.getConsolidationBtn"
                v-model="calculator.data.Dimensions.data.weight"
                @input.native="acceptNumber($event, 'weight')"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidations_heightCm'])"></div>
            <DefaultInput
                :label="$t('consolidations_heightCm.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.height}"
                :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.height}.localization_value.value`)"
                :error="calculator.data.Dimensions.validation.height"
                :disabled="$store.getters.getConsolidationBtn"
                v-model="calculator.data.Dimensions.data.height"
                @input.native="acceptNumber($event, 'height')"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidations_widthCm'])"></div>
            <DefaultInput
                :label="$t('consolidations_widthCm.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.width}"
                :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.width}.localization_value.value`)"
                :error="calculator.data.Dimensions.validation.width"
                :disabled="$store.getters.getConsolidationBtn"
                v-model="calculator.data.Dimensions.data.width"
                @input.native="acceptNumber($event, 'width')"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidations_lengthCm'])"></div>
            <DefaultInput
                :label="$t('consolidations_lengthCm.localization_value.value')"
                :type="'text'"
                :placeholder="'e.g. 10'"
                v-bind:class="{'ui-no-valid': calculator.data.Dimensions.validation.dimensionLength}"
                :errorTxt="$t(`${calculator.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
                :error="calculator.data.Dimensions.validation.dimensionLength"
                :disabled="$store.getters.getConsolidationBtn"
                v-model="calculator.data.Dimensions.data.dimensionLength"
                @input.native="acceptNumber($event, 'length')"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
            <DefaultSelect
                :options="calculator.getSenderWarehouseId() !== SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN.id ? countries: countriesUK"
                :label="$t('common_country.localization_value.value')"
                v-bind:class="{'ui-no-valid': calculator.validation.country}"
                :errorTxt="$t(`${calculator.validationTranslate.country}.localization_value.value`)"
                :error="calculator.validation.country"
                :optionsLabel="'label'"
                :otherValue="'currentTranslation'"
                @change="changeCountry"
                :selected="calculator.data.country"
            />
          </div>
          <div v-if="calculator.getCountry().id === USA_COUNTRY_ID" class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
            <DefaultSelect
                :options="states"
                :label="$t('common_region.localization_value.value')"
                v-bind:class="{'ui-no-valid': calculator.validation.countryRegion}"
                :errorTxt="$t(`${calculator.validationTranslate.countryRegion}.localization_value.value`)"
                :error="calculator.validation.countryRegion"
                :optionsLabel="'value'"
                @change="changeRegion"
                :selected="calculator.data.countryRegion"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
            <DefaultInput
                :label="$t('common_city.localization_value.value')"
                v-bind:class="{'ui-no-valid': calculator.validation.city}"
                :errorTxt="$t(`${calculator.validationTranslate.city}.localization_value.value`)"
                :error="calculator.validation.city"
                v-model="calculator.data.city"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--sm-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_zipCode'])"></div>
            <DefaultInput
                :label="$t('common_zipCode.localization_value.value')"
                v-bind:class="{'ui-no-valid': calculator.validation.postalCode}"
                :errorTxt="$t(`${calculator.validationTranslate.postalCode}.localization_value.value`)"
                :error="calculator.validation.postalCode"
                v-model="calculator.data.postalCode"
            />
          </div>
          <div class="custom-col custom-col--25 custom-col--sm-50 mb-0">

          </div>
<!--          <div class="custom-col"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['shippingCost_payPalClient',])"></div>-->
<!--            <DefaultCheckbox-->
<!--                :label="$t('shippingCost_payPalClient.localization_value.value')"-->
<!--                v-model="calculator.data.payPalClient"-->
<!--            />-->
<!--          </div>-->
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_calcCalculate'])"></div>
            <MainButton
                :value="$t('fbm_calcCalculate.localization_value.value')"
                @click.native="shippingCostCalculate"
                v-bind:class="{'disabled-btn' : $store.getters.getShippingCostBtn}"
            />
          </div>
        </div>


        <template v-if="!$store.getters.getShippingCostBtn && calcData && calcData.length > 0">
          <div class="block-result">
            <div class="block-result__title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_calcCalcResult'])"></div>
              {{$t('fbm_calcCalcResult.localization_value.value')}}
            </div>

            <div class="block-result__list">
              <div class="block-result__col"
                   v-for="(item, index) in calcData"
                   :key="index"
              >
                <div class="block-result__item">
                  <div
                      class="table-row"
                      style="margin-bottom: 12px;">
                    <div class="table-ico"
                         v-if="item.logo_file_name || item.logo_base64"
                         v-bind:class="{
                      'table-ico--large': item.logo_file_name === 'table-dpd-lg.svg' || item.logo_file_name === 'table-postnl-lg.svg',
                      'table-ico--mh32' : item.logo_file_name === 'table-postnl-lg.svg',
                    }"
                    >
                      <template v-if="item.logo_file_name">
                        <img :src="'/img/company-icons-group/' + item.logo_file_name" alt="img">
                      </template>
                      <template v-else-if="item.logo_base64">
                        <img :src="item.logo_base64" alt="img">
                      </template>
                    </div>

                    <!--                    <template v-if="item.type !== DPD_DELIVERY_SERVICE.name &&-->
                    <!--                          item.type !== POSTNL_DELIVERY_SERVICE.label &&-->
                    <!--                          item.data.delivery_method_id !== APC_CAMBRIDGE.deliveryCompanyId &&-->
                    <!--                          item.data.delivery_method_id !== ROYAL_MAIL_COMPANY.deliveryCompanyId"-->
                    <!--                    >-->
                    <!--                      <div class="plus ml-2 mr-2">-->
                    <!--                        +-->
                    <!--                      </div>-->
                    <!--                      <div class="table-ico">-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
                    <!--                              item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
                    <!--                              item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === APC_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                        <template v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name">-->
                    <!--                          <img src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->
                    <!--                        </template>-->
                    <!--                      </div>-->
                    <!--                    </template>-->


                  </div>
                  <div class="block-result__subtitle mb-0">
                    {{index + 1}}.
                    <template v-if="item.label">
                      {{item.label}}
                    </template>
                    <!--                    <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
                    <!--                      TNT Consolidation-->
                    <!--                    </template>-->
                    <!--                    <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
                    <!--                      FedEx Consolidation-->
                    <!--                    </template>-->
                    <!--                    <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
                    <!--                      <template v-if="item.data.title !== ''">-->
                    <!--                        {{item.data.title}}-->
                    <!--                      </template>-->
                    <!--                      <template v-else>-->
                    <!--                        DPD Consolidation-->
                    <!--                      </template>-->
                    <!--                    </template>-->
                    <!--                    <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
                    <!--                      <template v-if="item.data.title !== ''">-->
                    <!--                        {{item.data.title}}-->
                    <!--                      </template>-->
                    <!--                      <template v-else>-->
                    <!--                        PostNL Consolidation-->
                    <!--                      </template>-->
                    <!--                    </template>-->
                    <!--                    <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
                    <!--                      <span v-if="item.type !== GLOBAL24_SHIPPING_COMPANY.name.toLowerCase() &&-->
                    <!--                                item.data.delivery_method_id !== APC_CAMBRIDGE.deliveryCompanyId">+</span>-->
                    <!--                      <template v-if="item.data.name === 'Post NL(paid VAT)' && fromPoland">-->
                    <!--                        {{item.data.name.replace('(paid VAT)', '')}}-->
                    <!--                      </template>-->
                    <!--                      <template v-else>-->
                    <!--                        {{item.data.name}}-->
                    <!--                      </template>-->
                    <!--                    </template>-->
                    <!--                    <template v-else>-->
                    <!--                      <br>-->
                    <!--                      <div style="white-space: normal;" class="mt-1"-->
                    <!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                    <!--                        <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
                    <!--                        {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
                    <!--                      </div>-->
                    <!--                    </template>-->
                  </div>
                  <div class="block-result__content">
                    <table>
                      <tr>
                        <td>
                          <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>
                            {{$t('shippingCost_shipping.localization_value.value')}}
                          </div>
                        </td>
                        <td>
                          <template
                              v-if="item.price"
                          >
                            ${{item.price}}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </td>
                      </tr>
                      <!--                      <tr>-->
                      <!--                        <td>-->
                      <!--                          <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--                            <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
                      <!--                            {{$t('shippingCost_fee.localization_value.value')}}-->
                      <!--                          </div>-->
                      <!--                        </td>-->
                      <!--                        <td>-->
                      <!--                          <template-->
                      <!--                              v-if="item.data.fee"-->
                      <!--                          >-->
                      <!--                            ${{item.data.fee}}-->
                      <!--                          </template>-->
                      <!--                          <template v-else>-->
                      <!--                            - -->
                      <!--                          </template>-->
                      <!--                        </td>-->
                      <!--                      </tr>-->
                      <!--                      <tr>-->
                      <!--                        <td>-->
                      <!--                          <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--                            <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
                      <!--                            {{$t('shippingCost_total.localization_value.value')}}-->
                      <!--                          </div>-->
                      <!--                        </td>-->
                      <!--                        <td>-->
                      <!--                          <template>-->
                      <!--                            ${{calcTotal(item)}}-->
                      <!--                          </template>-->
                      <!--                        </td>-->
                      <!--                      </tr>-->
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

<!--        <template v-if="results && !$store.getters.getShippingCostBtn && (results.consolidation.length || results.express.length || results.easypost.length)">-->
<!--          <div class="block-result">-->
<!--          <div class="block-result__title"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['fbm_calcCalcResult'])"></div>-->
<!--            {{$t('fbm_calcCalcResult.localization_value.value')}}-->
<!--          </div>-->
<!--          <div class="block-result__list">-->
<!--            <div class="block-result__col"-->
<!--                 v-for="(item, index) in results.consolidation"-->
<!--                 :key="index"-->
<!--            >-->
<!--              <div class="block-result__item">-->
<!--&lt;!&ndash;                  <div class="table-row"&ndash;&gt;-->
<!--&lt;!&ndash;                       v-if="item.type === GLOBAL24_SHIPPING_COMPANY.name.toLowerCase()"&ndash;&gt;-->
<!--&lt;!&ndash;                       style="margin-bottom: 12px; min-height: 18px;">&ndash;&gt;-->
<!--&lt;!&ndash;                    &ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                <div-->
<!--                    class="table-row"-->
<!--                     style="margin-bottom: 12px;">-->
<!--                  <div class="table-ico"-->
<!--                       v-bind:class="{-->
<!--                    'table-ico&#45;&#45;large': item.type === DPD_DELIVERY_SERVICE.name || item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                    'table-ico&#45;&#45;mh32' : item.type === POSTNL_DELIVERY_SERVICE.label,-->
<!--                  }"-->
<!--                  >-->
<!--                    <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-else-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-else-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-else-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-else-if="item.type === USPS_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                    </template>-->
<!--&lt;!&ndash;                      <template v-else-if="item.type === APC_DELIVERY_SERVICE.name">&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="/img/company-icons-group/table-apc-lg.svg" alt="img">&ndash;&gt;-->
<!--&lt;!&ndash;                      </template>&ndash;&gt;-->
<!--                    <template v-else-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                      <img src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->
<!--                    </template>-->

<!--                    <template v-else>-->
<!--                      <img :src="item.data.logo_base64" alt="img">-->
<!--                    </template>-->
<!--                  </div>-->

<!--                  <template v-if="item.type !== DPD_DELIVERY_SERVICE.name &&-->
<!--                        item.type !== POSTNL_DELIVERY_SERVICE.label &&-->
<!--                        item.data.delivery_method_id !== APC_CAMBRIDGE.deliveryCompanyId &&-->
<!--                        item.data.delivery_method_id !== ROYAL_MAIL_COMPANY.deliveryCompanyId"-->
<!--                  >-->
<!--                    <div class="plus ml-2 mr-2">-->
<!--                      +-->
<!--                    </div>-->
<!--                    <div class="table-ico">-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                            item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                            item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name && item.type !== DPD_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === APC_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                      <template v-if="item.data.shipping_company.toLowerCase() === POSTNL_DELIVERY_SERVICE.name">-->
<!--                        <img src="/img/company-icons-group/table-postnl-lg.svg" alt="img">-->
<!--                      </template>-->
<!--                    </div>-->
<!--                  </template>-->


<!--                </div>-->
<!--                <div class="block-result__subtitle">-->
<!--                  {{index + 1}}.-->
<!--                  <template v-if="item.type === DHL_DELIVERY_SERVICE.name">-->
<!--                    DHL Consolidation-->
<!--                  </template>-->
<!--                  <template v-if="item.type === TNT_DELIVERY_SERVICE.name">-->
<!--                    TNT Consolidation-->
<!--                  </template>-->
<!--                  <template v-if="item.type === FEDEX_DELIVERY_SERVICE.name">-->
<!--                    FedEx Consolidation-->
<!--                  </template>-->
<!--                  <template v-if="item.type === DPD_DELIVERY_SERVICE.name">-->
<!--                    <template v-if="item.data.title !== ''">-->
<!--                      {{item.data.title}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      DPD Consolidation-->
<!--                    </template>-->
<!--                  </template>-->
<!--                  <template v-if="item.type === POSTNL_DELIVERY_SERVICE.label">-->
<!--                    <template v-if="item.data.title !== ''">-->
<!--                      {{item.data.title}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      PostNL Consolidation-->
<!--                    </template>-->
<!--                  </template>-->
<!--                  <template v-if="item.type !== DPD_DELIVERY_SERVICE.name && item.type !== POSTNL_DELIVERY_SERVICE.label">-->
<!--                    <span v-if="item.type !== GLOBAL24_SHIPPING_COMPANY.name.toLowerCase() &&-->
<!--                              item.data.delivery_method_id !== APC_CAMBRIDGE.deliveryCompanyId">+</span>-->
<!--                    <template v-if="item.data.name === 'Post NL(paid VAT)' && fromPoland">-->
<!--                      {{item.data.name.replace('(paid VAT)', '')}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      {{item.data.name}}-->
<!--                    </template>-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <br>-->
<!--                    <div style="white-space: normal;" class="mt-1"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['shippingCost_dpdCalcText'])"></div>-->
<!--                      {{$t('shippingCost_dpdCalcText.localization_value.value')}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </div>-->
<!--                <div class="block-result__content">-->
<!--                  <table>-->
<!--                    <tr>-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                          {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        <template-->
<!--                            v-if="item.data.price"-->
<!--                        >-->
<!--                          ${{item.data.price}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          - -->
<!--                        </template>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                          {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        <template-->
<!--                            v-if="item.data.fee"-->
<!--                        >-->
<!--                          ${{item.data.fee}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          - -->
<!--                        </template>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                          {{$t('shippingCost_total.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        <template>-->
<!--                          ${{calcTotal(item)}}-->
<!--                        </template>-->
<!--&lt;!&ndash;                          <template v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                            <template&ndash;&gt;-->
<!--&lt;!&ndash;                                v-if="item.data.price"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                              ${{item.data.price}}&ndash;&gt;-->
<!--&lt;!&ndash;                            </template>&ndash;&gt;-->
<!--&lt;!&ndash;                            <template v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                              - &ndash;&gt;-->
<!--&lt;!&ndash;                            </template>&ndash;&gt;-->
<!--&lt;!&ndash;                          </template>&ndash;&gt;-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                  </table>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="block-result__col"-->
<!--                 v-for="(item, index) in results.easypost"-->
<!--                 :key="index"-->
<!--            >-->
<!--              <div class="block-result__item">-->
<!--                <div class="table-row" style="margin-bottom: 12px;">-->
<!--                  <div class="table-ico table-ico&#45;&#45;large">-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === DHL_DELIVERY_SERVICE.name ||-->
<!--                item.data.shipping_company.toLowerCase() === DHLEXPRESS_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-dhl-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === TNT_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-tnt-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === FEDEX_DELIVERY_SERVICE.name ||-->
<!--                            item.data.shipping_company.toLowerCase() === FEDEXSMARTPOST_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-fedex-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === DPD_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-dpd-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === USPS_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-usps-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                    <template v-if="item.data.shipping_company.toLowerCase() === APC_DELIVERY_SERVICE.name">-->
<!--                      <img src="/img/company-icons-group/table-apc-lg.svg" alt="img">-->
<!--                    </template>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="block-result__subtitle">-->
<!--                  {{index + 1}}.-->
<!--                  {{item.data.name}}-->
<!--                </div>-->
<!--                <div class="block-result__content">-->
<!--                  <table>-->
<!--                    <tr>-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_shipping'])"></div>-->
<!--                          {{$t('shippingCost_shipping.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        <template-->
<!--                            v-if="item.data.shipmentCost"-->
<!--                        >-->
<!--                          ${{item.data.shipmentCost}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          - -->
<!--                        </template>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr v-if="!checkPayPalResult">-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_fee'])"></div>-->
<!--                          {{$t('shippingCost_fee.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        <template-->
<!--                            v-if="item.data.fee"-->
<!--                        >-->
<!--                          ${{item.data.fee}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          - -->
<!--                        </template>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    <tr v-if="!checkPayPalResult">-->
<!--                      <td>-->
<!--                        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                          <div class="admin-edit" @click="editTranslate(['shippingCost_total'])"></div>-->
<!--                          {{$t('shippingCost_total.localization_value.value')}}-->
<!--                        </div>-->
<!--                      </td>-->
<!--                      <td>-->
<!--                        ${{calcTotal(item)}}-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                  </table>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          </div>-->
<!--        </template>-->

        <DotsShadowPreloader
            v-else-if="$store.getters.getShippingCostBtn"
        />
        <NoResult
            v-else-if="firstResultLoad"
            :title="$t('common_noResult.localization_value.value')"
            :text="$t('common_noResultSeems.localization_value.value')"
        />
      </div>


    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    DHL_DELIVERY_SERVICE, DHLEXPRESS_DELIVERY_SERVICE, DPD_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    SHIPPING_COST_CALC_WAREHOUSE_UKRAINE,
    SHIPPING_COST_CALC_WAREHOUSE_LATVIA,
    // SHIPPING_COST_CALC_WAREHOUSE_USA,
    TNT_DELIVERY_SERVICE,
    POLAND_COUNTRY_ID, USA_COUNTRY_ID, USPS_DELIVERY_SERVICE,
    APC_DELIVERY_SERVICE, FEDEXSMARTPOST_DELIVERY_SERVICE,
    POSTNL_DELIVERY_SERVICE, GLOBAL24_SHIPPING_COMPANY, ROYAL_MAIL_COMPANY, APC_CAMBRIDGE, UNITED_KINGDOM_ID, SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN,
  } from "../../../../../staticData/staticVariables";
  import {Calculator} from "../../../../globalModels/Calculator";
  import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import NoResult from "../../../../coreComponents/NoResult/NoResult";
  import FromWarehouseBlock from "./components/FromWarehouseBlock/FromWarehouseBlock";
  // import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "ShippingCalculatorPopup",
    components: {
      FromWarehouseBlock,
      // DefaultCheckbox,
      NoResult,
      DotsShadowPreloader,
      Modal,
      MainButton,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      country: Object,
      dimensions: {
        type: Object,
        default: null
      },
      region: String,
      city: String,
      zip: String,
      fromUSA: Boolean,
    },

    computed: {
      language: function () {
        return this.$store.getters.GET_LANG
      },
    },

    data(){
      return {
        options: [{}],

        calculator: new Calculator(),
        createData: null,

        countries: [],
        countriesUK: [],
        states: [],

        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DPD_DELIVERY_SERVICE: DPD_DELIVERY_SERVICE,
        USPS_DELIVERY_SERVICE: USPS_DELIVERY_SERVICE,
        DHLEXPRESS_DELIVERY_SERVICE: DHLEXPRESS_DELIVERY_SERVICE,
        APC_DELIVERY_SERVICE: APC_DELIVERY_SERVICE,
        FEDEXSMARTPOST_DELIVERY_SERVICE: FEDEXSMARTPOST_DELIVERY_SERVICE,
        POSTNL_DELIVERY_SERVICE: POSTNL_DELIVERY_SERVICE,
        GLOBAL24_SHIPPING_COMPANY: GLOBAL24_SHIPPING_COMPANY,
        ROYAL_MAIL_COMPANY: ROYAL_MAIL_COMPANY,
        APC_CAMBRIDGE: APC_CAMBRIDGE,
        SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN: SHIPPING_COST_CALC_WAREHOUSE_GREAT_BRITAIN,

        USA_COUNTRY_ID: USA_COUNTRY_ID,
        POLAND_COUNTRY_ID: POLAND_COUNTRY_ID,

        firstResultLoad: false,
        checkPayPalResult: false,
        fromPoland: false,

        calcData: null,

        results: {
          'consolidation': [],
          'express': [],
          'easypost': []
        },
      }
    },

    mounted() {
      this.initCalc()
    },

    methods: {
      initCalc() {
        this.$store.dispatch('getShippingCostCalcCreate').then(response => {
          this.createData = this.getRespData(response)

          //get states data
          let statesData = this.createData?.states[Object.keys(this.createData?.states).find(Boolean)]

          for (const [key, value] of Object.entries(statesData)) {
            this.states.push({
              'key': key,
              'value': value
            })
          }

          // console.log('calc init', this.states);
        })

        this.$store.dispatch('fetchCountries').then(() => {
          this.countries = this._.toArray(this.$store.getters.getCountries).sort((x,y) => {
            return x.translationStorage[this.$store.getters.GET_LANG]?.name.localeCompare(y.translationStorage[this.$store.getters.GET_LANG]?.name, this.$store.getters.GET_LANG)
          })
          this.countriesUK = this._.toArray(this.$store.getters.getCountries).filter(item => {
            return item.id === UNITED_KINGDOM_ID
          })
          //sort countries list to place United States first in array and keep other countries unchanged
          this.countries = this.countries.sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })

          this.$watch('language', (newVal) => {
            this.countries = this.countries.sort((x,y) => {
              return x.translationStorage[newVal]?.name.localeCompare(y.translationStorage[newVal]?.name, newVal)
            }).sort(function(x,y){ return x.id === USA_COUNTRY_ID ? -1 : y.id === USA_COUNTRY_ID ? 1 : 0; })
          })

          //will be reworked later when Latvia warehouse will be added
          // if (this.fromUSA) {
          //   console.log(2222222);
          //   console.log('usa!');
          //   this.calculator.setSenderWarehouseId(SHIPPING_COST_CALC_WAREHOUSE_USA.id)
          // }
          // else if (this.isBaltic) {
          if (this.isBaltic) {
            this.calculator.setSenderWarehouseId(SHIPPING_COST_CALC_WAREHOUSE_LATVIA.id)
          }
          else {
            this.calculator.setSenderWarehouseId(SHIPPING_COST_CALC_WAREHOUSE_UKRAINE.id)
          }

          if (this.country) {
            this.calculator.setCountry(this.countries.find(findCountry => {
              return findCountry.id === this.country.id
            }))
          }

          if (this.region) {
            this.calculator.setCountryRegion(this.states.find(findState => {
              return findState.value === this.region.toUpperCase()
            }))
          }

          if(this.dimensions){
            this.calculator.data.Dimensions.setDimensionsWeight(this.dimensions.getDimensionsWeightLB())
            this.calculator.data.Dimensions.setDimensionsHeight(this.dimensions.getDimensionsHeight())
            this.calculator.data.Dimensions.setDimensionsWidth(this.dimensions.getDimensionsWidth())
            this.calculator.data.Dimensions.setDimensionsLength(this.dimensions.getDimensionsLength())
          }


          this.calculator.setCity(this.city)
          this.calculator.setPostalCode(this.zip)
        }).catch(error => this.createErrorLog(error))

      },

      changeCountry(val) {
        console.log(val);
        this.calculator.setCountry(val)
      },

      changeRegion(val) {
        console.log(val);
        this.calculator.setCountryRegion(val)
      },

      acceptNumber(event, type) {
        switch (type) {
          case 'weight':
            this.calculator.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          case 'length':
            this.calculator.data.Dimensions.data.dimensionLength = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          case 'width':
            this.calculator.data.Dimensions.data.width = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          case 'height':
            this.calculator.data.Dimensions.data.height = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          default:
            break
        }
      },

      shippingCostCalculate() {

        if (!this.calculator.dataValidation()) return

        this.firstResultLoad = true
        this.checkPayPalResult = this.calculator.data.payPalClient

        let prepareData = {
          'sender_warehouse_id': this.calculator.getSenderWarehouseId(),
          "recipient" : {
            "country_id" : this.calculator.getCountry()?.id,
            'country_region_id': this.calculator.getCountry()?.id === USA_COUNTRY_ID ? this.calculator.getCountryRegion()?.key : null,
            "postal_code" : this.calculator.getPostalCode(),
            "city" : this.calculator.getCity()
          },
          "dimension" : {
            'weight': this.calculator.data.Dimensions.getDimensionsWeight(),
            'length': this.calculator.data.Dimensions.getDimensionsLength(),
            'width': this.calculator.data.Dimensions.getDimensionsWidth(),
            'height': this.calculator.data.Dimensions.getDimensionsHeight(),
          },
          "pay_pal" : this.calculator.getPayPalClient()
        }



        console.log(this.calculator.data, prepareData)

        this.$store.dispatch('getShippingCostCalcResult', prepareData).then(response => {
          this.calcData = this.getRespData(response)?.price_list

          // let calcData = this.getRespData(response)
          //
          // let prepareCalcData = {
          //   'consolidation': [],
          //   'express': [],
          //   'easypost': [],
          // }
          //
          // this.fromPoland = false
          // if (calcData?.input_data) {
          //   this.fromPoland = calcData?.input_data?.sender_country?.id === this.POLAND_COUNTRY_ID
          // }
          //
          // for (const [key, value] of Object.entries(calcData)) {
          //
          //   if (value?.consolidation?.summary) {
          //     value?.consolidation?.summary.forEach(sumItem => {
          //       prepareCalcData['consolidation'].push({
          //         'type': key,
          //         'data': sumItem
          //       })
          //     })
          //   }
          //   if (key !== FEDEX_DELIVERY_SERVICE.name && value?.express && ((value?.express?.error || value?.express?.price))) {
          //     prepareCalcData['express'].push({
          //       'type': key,
          //       'data': value?.express
          //     })
          //   }
          //   if (key === FEDEX_DELIVERY_SERVICE.name && value?.express) {
          //     for (const [keyFedExp, valueFedExp] of Object.entries(value.express)) {
          //       if ((valueFedExp?.error || valueFedExp?.price)) {
          //         prepareCalcData['express'].push({
          //           'type': key,
          //           'packaging': keyFedExp,
          //           'data': valueFedExp
          //         })
          //       }
          //     }
          //   }
          //   if (key === 'easy_post' && value?.list) {
          //     value?.list.forEach(sumItem => {
          //       prepareCalcData['easypost'].push({
          //         'type': key,
          //         'data': sumItem
          //       })
          //     })
          //   }
          // }
          //
          // if (this.fromPoland) {
          //   prepareCalcData["consolidation"] = prepareCalcData["consolidation"].filter(item => {
          //     return item.type === GLOBAL24_SHIPPING_COMPANY.name.toLowerCase()
          //   })
          // }
          //
          // this.results = prepareCalcData

          console.log('calc test', this.results);
        }).catch(error => this.createErrorLog(error))
      },

      calcTotal(item) {
        return (+(item.data?.shipmentCost || item.data?.price || 0) + +(item.data?.fee || 0)).toFixed(2)
      },

    }

  }

</script>

<style lang="scss" scoped>
.block-result__col {
  max-width: 320px;
  width: 100%;
}
.block-result__subtitle {
  min-height: 32px;
}

.table-ico--large {
  width: 60px;
}

.table-ico--mh32 {
  max-height: 32px;

  img {
    max-height: 32px;
  }
}
</style>
