import { render, staticRenderFns } from "./OrdersFBATableContent.vue?vue&type=template&id=c1a0742e&scoped=true"
import script from "./OrdersFBATableContent.vue?vue&type=script&lang=js"
export * from "./OrdersFBATableContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1a0742e",
  null
  
)

export default component.exports