<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
                v-if="isAdmin"
            class="mt-1"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />
        <router-link
            :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + item.id"
            class="table-card__item-number">
          #{{item.id}}
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['delivery_date'] | moment('DD MMM, YYYY')"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="item"
                  :mobile="true"
              />
            </div>
          </div>

<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--              {{$t('fba_inboundID.localization_value.value')}}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <ValueHelper-->
<!--                  :value="item['inboundShipment']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('fba_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['comment']"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_orderStatus.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="site-status">
                <template v-if="!isStatusCompleted(item)">
                  <StatusIcoBtn :type="'in-progress'"/>
                </template>
                <template v-if="isStatusCompleted(item)">
                  <StatusIcoBtn :type="'delivered'"/>
                </template>
                <ValueHelper
                        :value="$t(`${_.find(FBA_ORDER_STATUS, {value: item['status']}).translation}.localization_value.value`)"
                />
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50" v-if="!isAdmin">
            <div class="table-card__item-label">
              {{ $t('fba_set.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['count_sets']"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_setCost.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
                <ValueHelper
                    :value="item['amazon_sets_cost']"
                />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fba_shippingCost.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['shipping_cost']"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i" v-if="isAdmin || !isStatusCompleted(item)">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.ordersFBA + '/' + item.id"
                :disabled="$store.getters.ordersFBABtn"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="isAdmin || !isStatusCompleted(item)">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
                :disabled="$store.getters.ordersFBABtn"
            />
          </div>
<!--          <div class="table-card__item-btn-i">-->
<!--            <LinkButton-->
<!--                :value="$t('common_copy.localization_value.value')"-->
<!--                :type="'copy'"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>

import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import {FBA_ORDER_STATUS} from "../../../../../../../../staticData/staticVariables";
import {FBAMixin} from "../../../../../../../../mixins/FBAMixins/FBAMixin";

export default {
  name: "OrdersFBATableMobile",

  components: {
    LinkButton,
    DefaultCheckbox,
    TableUserColumn,
    ValueHelper,
    StatusIcoBtn,
  },

  mixins: [FBAMixin],

  props: {
    item: Object,
    selectedNow: Boolean,
  },

  data() {
    return {
      show: false,
      FBA_ORDER_STATUS: FBA_ORDER_STATUS,
    }
  }

}
</script>

<style scoped>

</style>
