import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Dimensions} from "../../../globalModels/Dimensions";
import {Files} from "../../../globalModels/Files";
import {Product} from "../../../globalModels/Product";
import {User} from "../../../globalModels/User";
import {Order} from "../../../globalModels/Order";
import {
  FBA_ORDER_STATUS,
  FBA_ORDER_STATUSES,
} from "../../../../staticData/staticVariables";
import {ProductForCell} from "../../../globalModels/ProductForCell";
import {ProductSet} from "../../../globalModels/ProductSet";
import {ProductCells} from "../../../globalModels/ProductCells";
import {PersonalInformation} from "@/components/globalModels/PersonalInformation";
import {Delivery} from "@/components/globalModels/Delivery";

const moment = require('moment')
const _ = require('lodash');


export function FBA() {

  this.checkValid = checkValid

  this.data = {

    Order: new Order(),
    User: new User(),

    createdAt: '',

    userLabelType: 'my_label',
    userLabelTypes: {
      iHasLabel: 'my_label',
      warehouseBuyLabel: 'sklad_label'
    },

    Personal: new PersonalInformation(),
    Delivery: new Delivery(),

    /**
     * Product for add product in popup
     */
    productsDefault: [new Product()],

    Products: [new Product()],

    /**
     * products for admin manage cells
     */
    ProductForCell: new ProductForCell(),
    productsForCellArray: [],

    setDefault: new ProductSet(),
    sets: [],

    shippingLabel: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

    amazonFNSKU: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

    Dimensions: new Dimensions(),

    // weightOz: '',
    comment: '',
    shippingDate: '',

    /**
     * Variables for API SHIPPING METHODS
     */
    shippingPartnerType: {},
    shippingPartner: {},
    shippingAPIPrice: {},
    shippingAPIPrices: [],
    useEasyPost: false,
    shippingCompanies: [],
    /**
     * end
     */

    trackingNumber: '',
    upc: '',
    orderFee: '',
    packingCost: '',
    setCost: '',
    status: {
      name: '',
      value: '',
    },

    commission_for_more_one_in_set: null,
    order_process_fee: null,
  }

  this.validation = {
    comment: false,
    shippingDate: false,
    trackingNumber: false,
    upc: false,
    orderFee: false,
    packingCost: false,
    status: false,
  }

  this.validationTranslate = {
    comment: '',
    shippingDate: '',
    trackingNumber: '',
    upc: '',
    orderFee: '',
    packingCost: '',
    status: '',
  }

  this.validationTxt = {
    comment: false,
    shippingDate: false,
    trackingNumber: false,
    upc: false,
    orderFee: false,
    packingCost: false,
    status: false,
  }

  /**
   * Getters
   */

  this.getCreatedAt = () => {
    return this.data.createdAt
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getSetDefault = () => {
    return this.data.setDefault.getProductSet()
  }
  this.getProductsDefault = () => {
    return this.data.productsDefault
  }
  this.getSets = () => {
    return this.data.sets
  }
  // this.getWeightOzt = () => {
  //   return this.data.weightOz
  // }
  this.getShippingDate = () => {
    return this.data.shippingDate
  }
  this.getTrackingNumber = () => {
    return this.data.trackingNumber
  }
  this.getUpc = () => {
    return this.data.upc
  }
  this.getOrderFee = () => {
    return this.data.orderFee
  }
  this.getPackingCost = () => {
    return this.data.packingCost
  }
  this.getSetCost = () => {
    return this.data.setCost
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getProductsForCellArray = () => {
    return this.data.productsForCellArray
  }
  this.getUserLabelType = () => {
    return this.data.userLabelType
  }
  this.getShippingPartnerType = () => {
    return this.data.shippingPartnerType
  }
  this.getShippingPartner = () => {
    return this.data.shippingPartner
  }
  this.getShippingAPIPrice = () => {
    return this.data.shippingAPIPrice
  }
  this.getShippingAPIPrices = () => {
    return this.data.shippingAPIPrices
  }
  this.getShippingCompanies = () => {
    return this.data.shippingCompanies
  }


  /**
   * Setters
   */
  this.setOrderId = function (val) {
    this.data.Order.setId(val)
  }
  this.setCreatedAt = function (val) {
    this.data.createdAt = val
  }
  this.setProductsDefault = (val) => {
    if(val === ''){
      this.data.productsDefault = [new Product()]
    }
  }
  this.setSetDefault = (val) => {

    this.data.setDefault.setSkuSet(val?.skuSet ? val.skuSet : '')
    this.data.setDefault.setSetProducts([])

    if (val?.productsSet) {
      val.productsSet.map((item) => {
        this.data.setDefault.addNewProduct(item)
      })
    } else {
      this.data.setDefault.addNewProduct()
    }

  }
  this.setSets = (val) => {
    this.data.sets = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setShippingDate = (val) => {
    this.data.shippingDate = val
  }
  this.setWeightLb = (val) => {
    this.data.Dimensions.setDimensionsWeightLB(val)
  }
  this.setWeightOz = (val) => {
    this.data.Dimensions.setDimensionsWeightOZ(val)
    // this.data.weightOz = val
  }
  this.setTrackingNumber = (val) => {
    this.data.trackingNumber = val
  }
  this.setUpc = (val) => {
    this.data.upc = val
  }
  this.setOrderFee = (val) => {
    this.data.orderFee = val
  }
  this.setPackingCost = (val) => {
    this.data.packingCost = val
  }
  this.setSetCost = (val) => {
    this.data.setCost = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setUserLabelType = (val) => {
    this.data.userLabelType = val
  }
  this.setShippingPartnerType = (val) => {
    this.data.shippingPartnerType = val
  }
  this.setShippingPartner = (val) => {
    this.data.shippingPartner = val
  }
  this.setShippingAPIPrice = (val) => {
    this.data.shippingAPIPrice = val
  }
  this.setShippingAPIPrices = (val) => {
    this.data.shippingAPIPrices = val
  }
  this.setShippingCompanies = (val) => {
    this.data.shippingCompanies = val
  }
}



/**
 * *************** Global Setters
 */

FBA.prototype.sortCellArrayByStorageCount = function (userCellArray) {
  let sortByStorageCount = _.sortBy(userCellArray, function (item) {
    return 0 - item.data.storageCount
  })
  return sortByStorageCount
}

FBA.prototype.createProductModelAndCellsArray = function (productEntity, item) {
  /**
   * create and Product model
   * @type {Product}
   */
  let newProduct = new Product()
  newProduct.setProduct(productEntity.product, {
    id: productEntity.product.id,
    name: productEntity.product.name,
    count: productEntity.item_quantity,
    productModelId: item.id,
  })

  let cells = []
  productEntity.cells.map(cellItem => {
    let cellOptions = productEntity.cells.map(cell => {return cell.cell})
    let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
    cells.push(cell)
  })

  let existCell = cells.length > 0
  /**
   * Map product -> items_in_storage
   * if exist cell in storage and not selected =>
   * add this cell to productsForCellArray
   */
  productEntity.product?.items_in_storage.map(productInWarehouse => {
    if(productInWarehouse.damage) return

    /**
     * if this cell will be selected and saved
     * go to next productInWarehouse
     */
    if(existCell){
      let findExistCell = _.find(cells, function (findItem) {
        return findItem.data.cellSelected?.id === productInWarehouse.cell_id
      })
      /**
       * set storageCount from items_in_storage[index]
       */
      if(findExistCell) {
        findExistCell.data.storageCount = productInWarehouse.quantity
        return
      }
    }

    /**
     * set cellOptions in array productEntity.product?.items_in_storage
     */
    let cellOptions = _.filter(productEntity.product?.items_in_storage, function(cell) {
      if(productInWarehouse?.cell?.mixed && cell?.cell?.mixed) return cell.cell
      if(!productInWarehouse?.cell?.mixed && !cell?.cell?.mixed) return cell.cell
    })
    cellOptions = cellOptions.map(item => {return item.cell})

    let cell = new ProductCells(
      cellOptions,
      productInWarehouse.cell,
      productInWarehouse.quantity,
      false
    )

    cells.push(cell)
  })

  return {newProduct, cells, existCell}
}

FBA.prototype.addProductCountWithoutCell = function (product, productForCell) {
  product.product.items_in_storage.map(productInWarehouse => {
    if(!productInWarehouse.cell && productInWarehouse.cell_id) return
    if(productInWarehouse.damage) return

    if(!productInWarehouse.cell) {
      productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(productInWarehouse.quantity)
    }
  })
}

FBA.prototype.addProductExistCountWithoutCell = function (product, productForCell) {
  product.cells.map(cell => {
    if(!cell.cell && cell.cell_id) return

    if(!cell.cell) {
      productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
    }
  })
}

FBA.prototype.setProductsSets = function (sets, item) {
  sets.map((set) => {
    /**
     * create ProductSet Model
     * @type {ProductSet}
     */
    let newSet = new ProductSet()
    newSet.setSkuSet(set['set_sku'])

    /**
     * map for product_entities
     */
    let productEntities = set['product_entities']
    productEntities.map((productEntity) => {

      let {
        newProduct,
        cells,
        existCell
      } = this.createProductModelAndCellsArray(productEntity, item)

      /**
       * create and ProductForCell model with initialize Product
       * @type {ProductForCell}
       */
      let productForCell = new ProductForCell(newProduct)
      if(cells.length > 0){
        productForCell.setCellsByType(cells)
      }

      let newIndex = newSet.addNewProduct(productForCell)

      /**
       * Add Product Count Without Cell
       */
      this.addProductCountWithoutCell(productEntity, newSet.data.productsSet[newIndex])

      /**
       * Add Product Exist Count Without Cell
       */
      this.addProductExistCountWithoutCell(productEntity, newSet.data.productsSet[newIndex])

      /**
       * Sort ProductForCell in ProductsSet by storage quantity
       */
      if(!existCell){
        let userArray = newSet.data.productsSet[newIndex].data.UserCellArray
        newSet.data.productsSet[newIndex].data.UserCellArray = this.sortCellArrayByStorageCount(userArray)
      }
    })
    this.data.sets.push(newSet)
  })
}

FBA.prototype.setProductsForCellArray = function (productEntities, item) {
  productEntities.map((productEntity) => {

    let {
      newProduct,
      cells,
      existCell
    } = this.createProductModelAndCellsArray(productEntity, item)

    /**
     * set product to ProductForCell model
     * and add to productsForCellArray array
     */
    let newIndex = this.addProductForCells(newProduct, cells)

    /**
     * Sort ProductsForCellArray by storage quantity
     */

    /**
     * Add Product Count Without Cell
     */
    this.addProductCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    /**
     * Add Product Exist Count Without Cell
     */
    this.addProductExistCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    if(!existCell){
      let userArray = this.data.productsForCellArray[newIndex].data.UserCellArray
      this.data.productsForCellArray[newIndex].data.UserCellArray = this.sortCellArrayByStorageCount(userArray)
    }

  })
}

FBA.prototype.setItem = function (item) {
  // this.data.Order.setId(item['id'])
  this.data.Order.setOrderOBJ(item)
  this.data.User.setUser(item['user'])

  this.data.Order.setHistoryOfChanged(item['activityUUID'])
  // this.data.Order.setWarehouse(item['warehouse_storage'])

  this.data.Order.setItem({
    id: item['id'],
    status: _.find(FBA_ORDER_STATUSES, {value: item['status']}),
    oldStatus: _.find(FBA_ORDER_STATUSES, {value: item['status']}),
    // type: _.find(FBM_ORDER_TYPES, {id: item['order_type_id']}),
    // trackingNum: item['tracking_number'],
    warehouse: item['warehouse_storage'],
    isWasBeenCreateLabel: item?.label_file.length > 0 ? item.label_file[0] : null,
    fileLabel: item?.label_file.length > 0 ? item.label_file[0] : null,
    // isDraft: item['in_draft'] === 1,
    createdAt: item['created_at'],
    // transactionType: item?.payment_transaction?.payment_system ? item.payment_transaction.payment_system : null,
    // transactionId: item?.payment_transaction?.transaction_id ? item.payment_transaction.transaction_id : null
  })

  let productEntities = item['product_entities'],
      sets = item['fba_sets'],
      dimensions = item['order_dimensions'],
      orderFbaLabelInfo = item?.order_fba_label_info,
      rate = item.order_fba_label_info?.rate

  /**
   * set ProductsForCellArray
   */
  this.setProductsForCellArray(productEntities, item)

  /**
   * set ProductsSets
   */
  this.setProductsSets(sets, item)


  if (dimensions) {
    let itemDimensions = this.data.Dimensions
    itemDimensions.setDimensionsWeightLB(dimensions?.imperial?.weight_lb !== null ?
        dimensions['imperial']['weight_lb'] : '')
    itemDimensions.setDimensionsWeightOZ(dimensions?.imperial?.weight_oz !== null ?
        dimensions['imperial']['weight_oz'] : '')
    itemDimensions.setDimensionsHeight(dimensions['height'] ?
        dimensions['height'] : '')
    itemDimensions.setDimensionsLength(dimensions['length'] ?
        dimensions['length'] : '')
    itemDimensions.setDimensionsWidth(dimensions['width'] ?
        dimensions['width'] : '')
  }

  this.setUserLabelType(item.label_precess_type || this.data.userLabelTypes.iHasLabel)

  if(orderFbaLabelInfo) {
    this.data.Delivery.setDeliveryItem({
      address: orderFbaLabelInfo?.recipient_address,
      address2: orderFbaLabelInfo?.recipient_address_1,
      city: orderFbaLabelInfo?.recipient_city,
      region: orderFbaLabelInfo?.recipient_region,
      country: orderFbaLabelInfo?.recipient_country ? {
        id: orderFbaLabelInfo.recipient_country.id,
        label: orderFbaLabelInfo.recipient_country.name
      } : null ,
      zip: orderFbaLabelInfo?.recipient_zip_code,
    })

    this.data.Personal.setPersonalItem({
      personalName: orderFbaLabelInfo?.recipient_contact_name,
      personalPhone: orderFbaLabelInfo?.recipient_phone_number,
      // personalEmail
      // personalCompany
    })
  }


  this.setComment(item['comment'] ? item['comment'] : '')
  this.setTrackingNumber(item['tracking_number'] ? item['tracking_number'] : '')
  this.setShippingDate(item['delivery_date'] ? item['delivery_date'] : null)
  this.setCreatedAt(item['created_at'] ? item['created_at'] : '')
  this.setOrderFee(item['order_fee'] ? item['order_fee'] : 0)
  this.setPackingCost(item['packing_cost'] ? item['packing_cost'] : 0)
  this.setSetCost(item['amazon_sets_cost'] ? item['amazon_sets_cost'] : '')

  if(orderFbaLabelInfo && _.has(orderFbaLabelInfo['delivery_method'], 'shipping_company')){
    this.setShippingPartner(orderFbaLabelInfo['delivery_method'])
    this.setShippingPartnerType(orderFbaLabelInfo['delivery_method']['shipping_company'])
  }

  if(_.has(rate, 'driver_delivery_method_id') && rate['driver_delivery_method_id']){
    this.setShippingAPIPrice(rate)
    this.setShippingAPIPrices([rate])
  }

  if (item['status']) {
    let itemStatus = _.find(FBA_ORDER_STATUS, ['value', item['status']])
    this.setStatus(itemStatus)
  }

  if(item['commission_for_more_one_in_set']){
    this.data.commission_for_more_one_in_set = item['commission_for_more_one_in_set']
  }

  if(item['order_process_fee']){
    this.data.order_process_fee = item['order_process_fee']
  }

}

/**
 * Functions
 */


FBA.prototype.addProduct = function (array) {
  array.push(new Product)
  return array.length - 1
}

FBA.prototype.removeProduct = function (array, index) {
  if (array.length === 1) {
    array.splice(index, 1)
    array.push(new Product)
    return
  }
  array.splice(index, 1)
}

FBA.prototype.resetProduct = function () {
  this.data.Products = [new Product]
}

FBA.prototype.resetProductIntoSet = function () {
  this.data.set.productsSet = [new Product]
}

FBA.prototype.resetSets = function () {
  this.data.sets = []
}

FBA.prototype.addProductForCells = function (product = false, cells = false) {
  let productForCell = new ProductForCell(product)
  if(cells.length > 0){
    productForCell.setCellsByType(cells)
  }
  this.data.productsForCellArray.push(productForCell)
  return this.data.productsForCellArray.length - 1
}

FBA.prototype.addProductForCellsToArray = function (array) {
  array.push(new ProductForCell())
}

FBA.prototype.removeProductForCells = function (array, index) {
  array.splice(index, 1)
}

FBA.prototype.addNewSet = function (set) {
  this.data.sets.push(set)
  return this.data.sets.length - 1
}

FBA.prototype.addNewSetToSets = function () {
  this.data.sets.push({
    skuSet: '',
    productsSet: [new Product()]
  })

  return this.data.sets.length - 1
}

FBA.prototype.updateSetInSets = function (index) {

  let itemSet = this.data.sets[index]
  let object = JSON.parse(JSON.stringify(this.getSetDefault()))

  itemSet.skuSet = object?.skuSet ? object.skuSet : ''
  itemSet.productsSet = []

  object.productsSet.map((item, index) => {
    itemSet.productsSet.push(new Product())
    itemSet.productsSet[index].setProduct({
      id: item.product.id,
      name: item.product.name,
      description: item.product.description,
      count: item.product.count,
      price: item.product.price,
      sku_code: item.product.skuCode,
    })
  })
}

FBA.prototype.removeSetFromSets = function (index) {
  this.data.sets.splice(index, 1)
}

FBA.prototype.setFiles = function (val, type) {
  switch (type.id) {
      //shippingLabel
    case 1:
      this.data.shippingLabel.pushDownloadFiles(val)
      break
      //amazonFNSKU
    case 2:
      this.data.amazonFNSKU.pushDownloadFiles(val)
      break
  }
}

FBA.prototype.removeFile = function (id, type) {
  let i = -1
  switch (type.id) {
      //shippingLabel
    case 1:
      _.findIndex(this.data.shippingLabel.getDownloadFiles(), function (item, index) {
        if (item[id] !== undefined) i = index
      });

      this.data.shippingLabel.removeDownloadFile(i)
      break
      //amazonFNSKU
    case 2:
      _.findIndex(this.data.amazonFNSKU.getDownloadFiles(), function (item, index) {
        if (item[id] !== undefined) i = index
      });

      this.data.amazonFNSKU.removeDownloadFile(i)
      break
  }
}

FBA.prototype.productsDataGeneration = function (array, withCells = false) {
  let data = []
  array.map(item => {
    let product = item.data.Product.product
    let productId = product?.id

    if(!productId) return

    let itemData = {
      "product_id": productId,
      "item_quantity": product.count,
    }
    if(withCells){
      let cells = [],
        userCellArray = item.data.UserCellArray,
        mixedCellArray = item.data.MixedCellArray

      cells = [...cells, ...this.getCellsByArray(userCellArray, productId)]
      cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId)]

      if(parseInt(item.data.withoutCellEndCounts) !== 0) {
        cells.push({
          "product_id": productId,
          "cell_id": null,
          "item_quantity": parseInt(item.data.withoutCellEndCounts),
        })
      }

      itemData["cells"] = cells
    }
    data.push(itemData)
  })
  return data
}

FBA.prototype.getCellsByArray = function (array, productId) {
  let cells = []
  array.map(itemCell => {
    if(itemCell.data.cellCount > 0){
      cells.push({
        "product_id": productId,
        "cell_id": itemCell.data.cellSelected.id,
        "item_quantity": parseInt(itemCell.data.cellCount),
      })
    }
  })
  return cells
}

/**
 * Validations
 */

FBA.prototype.isHasSelectedProducts = function () {
  let selected = false
  this.data.productsForCellArray.map(item => {
    if (item.data.Product.product?.id) {
      selected = true
    }
  })
  return selected;
}

// FBA.prototype.getValidationProductsByCount = function (isHasSelectedProduct) {
//   let isHasSet = (this.getSets().length !== 0)
//
//   console.log(111, isHasSelectedProduct, isHasSet, '----> ')
//
//   if (!isHasSet ||
//       (isHasSet && this.data.productsForCellArray.length > 1) ||
//       (isHasSet && isHasSelectedProduct) ||
//       (!isHasSet && isHasSelectedProduct)
//   ) {
//     return true
//   }
// }

FBA.prototype.productsDefaultValidation = function () {
  let valid = true

  this.data.productsDefault.map(item => {
    if (!item.productValidate({
      productObject: true,
      count: true
    }))
      valid = false
  })

  return valid
}

FBA.prototype.productsValidation = function () {
  let valid = true

  let isHasSelectedProduct = this.isHasSelectedProducts()
  let isHasSelectedSet = this.data.sets.length > 0

  if(isHasSelectedProduct || (!isHasSelectedProduct && !isHasSelectedSet)){
    this.data.productsForCellArray.map(item => {
      if (!item.data.Product.productValidate({
        productObject: true,
        count: true
      }))
        valid = false
    })
  }

  return valid
}

// FBA.prototype.productsInSetValidation = function () {
//   let valid = true
//
//   let validationItems = {
//     skuSet: this.data.setDefault.getSkuSet(),
//   }
//
//   let validationOptions = {
//     skuSet: {type: ['empty']},
//   }
//
//   this.data.setDefault.getSetProducts().map(item => {
//     if (!item.productValidate({productObject: true, count: true}))
//       valid = false
//   })
//
//   return (this.checkValid(validationItems, validationOptions) && valid)
// }

FBA.prototype.shipmentDetailsValidation = function () {
  let valid = true

  if (this.data.shippingLabel.data.files.length > 0 &&
      !this.data.shippingLabel.firstValidation()) valid = false
  if (this.data.amazonFNSKU.data.files.length > 0 &&
      !this.data.amazonFNSKU.firstValidation()) valid = false

  // if(this.data.userLabelTypes.warehouseBuyLabel === this.data.userLabelType) {
  //   if(valid) {
  //     valid = this.data.Personal.personalInfoPartsValidate({
  //       personalName: true,
  //       emailOptional: true,
  //     }) && this.data.Delivery.deliveryInfoValidate()
  //   }
  // }

  if(this.data.userLabelTypes.warehouseBuyLabel === this.data.userLabelType) {
    if(valid) {
      valid = this.data.Personal.personalInfoPartsValidate({
        personalName: true,
        personalPhone: true,
        emailOptional: true,
      }) && this.data.Delivery.deliveryInfoValidate() &&
        this.data.Dimensions.dimensionsPartialValidate({
          name: false,
          weight: false,
          height: true,
          width: true,
          dimensionLength: true,
          widthLB: false,
          widthOZ: false,
          weightLB: true,
          weightOZ: true,
        })
    }
  }

  return valid
}

FBA.prototype.updateAdminValidation = function (statusCompleted) {
  let data = this.data
  let valid = true

  let validationItems = {
    shippingDate: data.shippingDate,
  }

  let validationOptions = {
    shippingDate: {type: statusCompleted ? ['empty'] : []},
  }

  if(this.data.userLabelTypes.warehouseBuyLabel === this.data.userLabelType) {
    if(valid) {
      valid = this.data.Personal.personalInfoPartsValidate({
          personalName: true,
          emailOptional: true,
        }) && this.data.Delivery.deliveryInfoValidate() &&
        this.data.Dimensions.dimensionsPartialValidate({
          name: false,
          weight: false,
          height: true,
          width: true,
          dimensionLength: true,
          widthLB: false,
          widthOZ: false,
          weightLB: true,
          weightOZ: true,
        })
    }
  }

  return ((this.checkValid(validationItems, validationOptions)) && valid)
}

FBA.prototype.checkProductQuantityInCells = function () {
  let valid = true
  let errorProducts = []

  this.data.productsForCellArray.map(productsForCell => {
    let cellArraysCounts = productsForCell.getCellArraysCounts()
    let allCounts = cellArraysCounts.userCellCounts + cellArraysCounts.mixedCellCounts + parseInt(productsForCell.data.withoutCellEndCounts)
    if(allCounts !== productsForCell.data.productCount){
      valid = false
      errorProducts.push(productsForCell)
    }
  })
  this.data.sets.map(set => {
    set.data.productsSet.map(productsForCell => {
      let cellArraysCounts = productsForCell.getCellArraysCounts()
      let allCounts = cellArraysCounts.userCellCounts + cellArraysCounts.mixedCellCounts + parseInt(productsForCell.data.withoutCellEndCounts)
      if(allCounts !== productsForCell.data.productCount){
        valid = false
        errorProducts.push(productsForCell)
      }
    })
  })

  return {valid, errorProducts}
}

FBA.prototype.checkProductQuantityInStorage = function () {
  let valid = true
  let errorProducts = []

  this.data.productsForCellArray.map(productsForCell => {
    if(!productsForCell.checkRatioCellArraysCounts()){
      valid = false
      errorProducts.push(productsForCell)
    }
  })
  this.data.sets.map(set => {
    set.data.productsSet.map(productsForCell => {
      if(!productsForCell.checkRatioCellArraysCounts()){
        valid = false
        errorProducts.push(productsForCell)
      }
    })
  })

  return {valid, errorProducts}
}

/**
 * Prepare Data
 */


FBA.prototype.prepareGetPrices = function (adminManage = false) {
  let data = this.data,
    personal = data.Personal,
    delivery = data.Delivery,
    dimensions = data.Dimensions,
    order = data.Order,
    typeDimensions = 'dimension'

  if(adminManage){
    typeDimensions = 'admin_dimension'
  }

  let prepareData = {
    'user_id': data.User.getUserId(),
    // "order_type_id" : prepareFBM.order_type_id,
    'recipient_contact_name': personal.getPersonalName(),
    'recipient_phone_number' : personal.getPersonalPhone(),
    "recipient_company_name": null,
    'recipient_address': delivery.getAddress(),
    'recipient_address_1': delivery.getAddress2(),
    'recipient_city': delivery.getCity(),
    'recipient_region': delivery.getRegion(),
    'recipient_country_id': delivery.getCountry()?.id || null,
    'recipient_zip_code': delivery.getZip(),
    'delivery_company_id' : this.getShippingPartnerType()?.delivery_company_id || null,
    'delivery_method_id' : this.getShippingPartner()?.id || null,
    'storage_id': order.getWarehouse()?.id || null,

    [typeDimensions]: [{
      'weightLb': dimensions.getDimensionsWeightLB(),
      'weightOz': dimensions.getDimensionsWeightOZ(),
      'height': dimensions.getDimensionsHeight(),
      'width': dimensions.getDimensionsWidth(),
      'length': dimensions.getDimensionsLength()
    }]
  }

  if(this.getShippingDate()) {
    prepareData['delivery_date'] = moment(this.getShippingDate()).format('YYYY-MM-DD')
  }

  return prepareData
}



FBA.prototype.prepareSave = function ({isAdmin = false, createForUser = false}) {
  let data = this.data
  let setsData = []
  let order = this.data.Order
  let personal = this.data.Personal
  let delivery = this.data.Delivery

  this.data.sets.map(item => {
    setsData.push({
      "set_sku": item.getSkuSet(),
      "product_data": this.productsDataGeneration(item.data.productsSet, isAdmin),
    })
  })

  let prepareData = {
    'user_id': data.User.getUserId(),
    'storage_id': order.getWarehouse()?.id ? order.getWarehouse().id : null,
    'comment': this.getComment() ? this.getComment() : null,
    'product_data': this.productsDataGeneration(data.productsForCellArray, isAdmin),
    'sets_data': setsData,

    'label_precess_type': this.data.userLabelType,
    'in_draft': 0,
  }

  if(this.data.userLabelTypes.warehouseBuyLabel === this.data.userLabelType || isAdmin) {
    prepareData['dimension'] = [
      {
        'weightLb': parseFloat(data.Dimensions.getDimensionsWeightLB()),
        'weightOz': parseFloat(data.Dimensions.getDimensionsWeightOZ()),
        'height': parseFloat(data.Dimensions.getDimensionsHeight()),
        'width': parseFloat(data.Dimensions.getDimensionsWidth()),
        'length': parseFloat(data.Dimensions.getDimensionsLength())
      }
    ]

    if(this.data.userLabelTypes.warehouseBuyLabel === this.data.userLabelType) {
      prepareData['order_fba_label_info'] = {
        recipient_company_name: null,
        recipient_contact_name: personal.getPersonalName(),
        recipient_phone_number: personal.getPersonalPhone(),
        recipient_address: delivery.getAddress(),
        recipient_address_1: delivery.getAddress2(),
        recipient_city: delivery.getCity(),
        recipient_region: delivery.getRegion(),
        recipient_country_id: delivery.getCountry()?.id,
        recipient_zip_code: delivery.getZip(),
        recipient_email: personal.getPersonalEmail(),
        insurance_cost: null,
      }
    }


    if(this.getShippingPartnerType()?.delivery_company_id){
      prepareData['order_fba_label_info']['delivery_company_id'] = this.getShippingPartnerType()?.delivery_company_id
    }

    if(this.getShippingPartner()?.id){
      prepareData['order_fba_label_info']['delivery_method_id'] = this.getShippingPartner()?.id
    }

    if(this.getShippingAPIPrice()?.driver_delivery_method_id){
      prepareData['order_fba_label_info']['rate'] = this.getShippingAPIPrice()
      if(this.getShippingAPIPrice()?.delivery_method_id){
        prepareData['order_fba_label_info']['delivery_method_id'] = this.getShippingAPIPrice()?.delivery_method_id
      }
    }
  }



  prepareData['shipping_label_file_base64'] = data.shippingLabel.getFiles().length > 0 ?
      _.first(data.shippingLabel.getFiles())['base64String'] : null
  prepareData['fnsku_file_base64'] = data.amazonFNSKU.getFiles().length > 0 ?
      _.first(data.amazonFNSKU.getFiles())['base64String'] : null

  if (isAdmin && !createForUser) {
    let prepareDataForAdmin = {
      "tracking_number": this.getTrackingNumber(),
      "delivery_date": this.getShippingDate() ? moment(this.getShippingDate()).format('YYYY-MM-DD') : null,

      "packing_cost": parseFloat(this.getPackingCost() ? this.getPackingCost() : 0),
    }

    if(Number(this.data.Order.data.orderOBJ?.order_fee) !== Number(this.getOrderFee())) {
      prepareDataForAdmin['admin_fee'] = this.getOrderFee()
    } else {
      prepareDataForAdmin["order_fee"] = parseFloat(this.getOrderFee() ? this.getOrderFee() : 0)
    }

    if (this.getStatus().value !== '') {
      prepareDataForAdmin["status"] = this.getStatus().value
    }

    prepareData = Object.assign(prepareData, prepareDataForAdmin)
  }

  return prepareData

}

