<template>
  <div class="fragment"
       v-bind:class="{'loading-products': productLoading}"
  >
    <SelectProductBlock
        v-for="(item, index) in products"
        :autoloadSelect="true"
        :initialOptionsProducts="initialOptionsProducts"
        :key="index+'product'"
        :item="item.data.Product"
        :price="false"
        :description="false"
        :userId="parseInt(user.userId)"
        :productLabel="'name_for_fba'"
        :largeNameColumn="fbaAdminCondition"
        :withDraft="false"
        @removeProduct="FBA.removeProductForCells(products, index)"
        @changeProductName="changeProductName"
        @changeProductQuantity="changeProductQuantity"
    />
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--83 custom-col--md-100">
        <div class="add-product-btn-wrap mb-0"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
          <span class="order-create__product-btn-i add-product-btn site-link"
                @click="FBA.addProductForCells()">
                + {{$t('common_AddProduct.localization_value.value')}}
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectProductBlock from "../../../../../../coreComponents/SelectProductBlock/SelectProductBlock";
import {FBAMixin} from "../../../../../../../mixins/FBAMixins/FBAMixin";

export default {
  name: "ProductsBlock",
  components: {
    SelectProductBlock,
  },

  mixins: [
    FBAMixin,
  ],

  props: {
    FBA: Object,
    user: Object,
    products: Array,
    initialOptionsProducts: Array,
    productLoading: Boolean,
    fbaAdminCondition: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>
.loading-products{
  opacity: 0.5;
  pointer-events: none;
}
</style>
