<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
      <div class="user-info-admin__caption"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_identifier'])"></div>
        {{ $t('fba_identifier.localization_value.value') }}
<!--        Identifier-->
      </div>
      <div class="user-info-admin__large">
        {{ order.data.id }}
      </div>
      <div class="user-info-admin__desk">
        {{ FBA.data.createdAt | moment("DD MMM, YYYY") }}
      </div>

      <div class="user-info-admin__history">
        <div v-if="FBA.data.Order.data.historyOfChanged"
             class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
               @click="historyOfChangesFunc('fba', FBA.data.Order.data.historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>
      <div class="user-info-admin__status"
          :class="{ 'in-progress' : FBA.data.status.value === 'in_progress'}"
      >
        {{ $t(`${FBA.data.status.translation}.localization_value.value`) }}
      </div>
      <div class="user-info-admin__bio">

        <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`" class="user-info-admin__photo"
             v-if="user.userAvatar.id && user.userAvatar.base64"
        >
          <img :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`"
               alt="img" width="32" height="32">
        </router-link>
        <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`"
                     class="user-info-admin__photo background-gray border-light-brown--2px"
                     v-else>
          <img src="/img/profile-group/profile-photo-default.svg" alt="img" width="32" height="32">
        </router-link>
        <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`"
                     class="user-info-admin__name"
                      v-if="_.first(user.user.contacts)"
        >
          {{_.first(user.user.contacts)['user_full_name']}}
        </router-link>
      </div>



      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="'Customer Information'"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeInformationDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>
                {{$t('fbm_ContactName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="personal.information.personalName"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_CompanyName'])"></div>
                {{$t('fbm_CompanyName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="personal.information.personalCompany"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Email'])"></div>
                {{$t('fbm_Email.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="personal.information.personalEmail"
                />
              </div>
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_PhoneNumber.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                <ValueHelper
                    :value="personal.information.personalPhone"
                />
              </div>
            </div>

            <!--<div class="block-table__col mb-3">-->
            <!--<TitleHorizontal-->
            <!--class="black mb-0"-->
            <!--:value="'Order Items'"-->
            <!--:rightBtn="true"-->
            <!--:rightBtnType="'edit'"-->
            <!--:rightBtnText="'Edit'"-->
            <!--@click.native="changeChangeDataPopup(changeDataItems)"-->
            <!--/>-->
            <!--</div>-->

            <!--<div class="block-table__col mb-3">-->
            <!--<div class="block-table__label">-->
            <!--Item Number-->
            <!--</div>-->
            <!--<div class="block-table__content">-->
            <!--434654745-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="block-table__col mb-3">-->
            <!--<div class="block-table__label">-->
            <!--Consolidation-->
            <!--</div>-->

            <!--<div class="block-table__content">-->
            <!--th_name_of _the_co...-->
            <!--</div>-->
            <!--</div>-->

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_Address.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @click.native="changeDeliveryDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                {{$t('fbm_Address.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.address"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{$t('common_addressSecond.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.address2"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                {{$t('fbm_City.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.city"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                {{$t('fbm_Region.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.region"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                {{$t('fbm_ZipCode.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.zip"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                {{$t('fbm_Country.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="delivery.delivery.country"
                    :deep="'label'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <ChangeDataPopup
        v-if="isModalChangeAddressPopup"
        :items="changeDataPopup"
        :popupTitle="popupTitle"
        :getRegionsIfUSA="false"
        @save="saveOrderData"
        @close="isModalChangeAddressPopup = false"
    />

    <HistoryOfChanges
        v-if="isModalHistoryOfChanges"
        @close="changeHistoryOfChanges(false)"
    />
  </div>
</template>

<script>
  import HistoryOfChanges from "../../../../../popups/HistoryOfChanges/HistoryOfChanges";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
  import {PersonalInformation} from "@/components/globalModels/PersonalInformation";
  import {Delivery} from "@/components/globalModels/Delivery";
  import ChangeDataPopup from "@/components/modules/OrdersFBAModule/popups/ChangeDataPopup/ChangeDataPopup";

  export default {
    name: "UserInfo",
    components: {
      ChangeDataPopup,
      TitleHorizontal,
      ValueHelper,
      HistoryOfChanges,
    },

    props:{
      FBA: Object,
      user: Object,
      order: Object,
      personal: Object,
      delivery: Object,
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
        /**
         * Fake models for check validation on Change Data Popup
         */
        PI: new PersonalInformation(),
        Delivery: new Delivery(),

        isModalChangeAddressPopup: false,
        popupTitle: '',
        changeDataPopup: [],
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },


      changeInformationDataPopup(){

        this.PI.setPersonalName(this.personal.information.personalName)
        this.PI.setPersonalPhone(this.personal.information.personalPhone)
        this.PI.setPersonalCompany(this.personal.information.personalCompany)
        this.PI.setPersonalEmail(this.personal.information.personalEmail)

        this.setPopupInformationData()
        this.typeFieldsChanges = 'personal'
        this.popupTitle = 'common_personalChanges'
        this.isModalChangeAddressPopup = true
      },

      changeDeliveryDataPopup(){

        this.Delivery.setAddress(this.delivery.delivery.address)
        this.Delivery.setAddress2(this.delivery.delivery.address2)
        this.Delivery.setCity(this.delivery.delivery.city)
        this.Delivery.setRegion(this.delivery.delivery.region)
        this.Delivery.setCountry(this.delivery.delivery.country)
        this.Delivery.setZip(this.delivery.delivery.zip)

        this.setPopupDeliveryData()
        this.typeFieldsChanges = 'delivery'
        this.popupTitle = 'common_deliveryChanges'
        this.isModalChangeAddressPopup = true
      },


      /**
       * Personal Data Update
       */
      setPopupInformationData() {
        this.changeDataPopup = [
          {
            field: 'name',
            name: 'fbm_ContactName',
            type: 'text',
            value: this.PI.information.personalName,
            valid: this.PI.validation.personalName,
            validTxt: this.PI.validationTranslate.personalName,
          },
          {
            field: 'phone',
            name: 'fbm_PhoneNumber',
            type: 'text',
            value: this.PI.information.personalPhone,
            valid: this.PI.validation.personalPhone,
            validTxt: this.PI.validationTranslate.personalPhone,
          },
          {
            field: 'company',
            name: 'fbm_CompanyName',
            type: 'text',
            value: this.PI.information.personalCompany,
            valid: this.PI.validation.personalCompany,
            validTxt: this.PI.validationTranslate.personalCompany,
          },
          {
            field: 'email',
            name: 'fbm_Email',
            type: 'text',
            value: this.PI.information.personalEmail,
            valid: this.PI.validation.personalEmail,
            validTxt: this.PI.validationTranslate.personalEmail,
          },
        ]
      },

      personalUpdate(data) {
        this.PI.setPersonalName(this._.find(data, {field: 'name'}).value)
        this.PI.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
        this.PI.setPersonalCompany(this._.find(data, {field: 'company'}).value)
        this.PI.setPersonalEmail(this._.find(data, {field: 'email'}).value)

        let validate = this.PI.personalInfoPartsValidate({
          personalName: true,
          // personalPhone: true,
          emailOptional: true,
        })

        if(!validate){
          this.setPopupInformationData()
          return
        }

        this.isModalChangeAddressPopup = false
        this.personal.setPersonalName(this._.find(data, {field: 'name'}).value)
        this.personal.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
        this.personal.setPersonalCompany(this._.find(data, {field: 'company'}).value)
        this.personal.setPersonalEmail(this._.find(data, {field: 'email'}).value)
      },



      /**
       * Delivery Data Update
       */
      setPopupDeliveryData() {
        this.changeDataPopup = [
          {
            field: 'address',
            name: 'fbm_Address',
            type: 'text',
            value: this.Delivery.delivery.address,
            valid: this.Delivery.validation.address,
            validTxt: this.Delivery.validationTranslate.address,
            maxLength: 35,
            autocomplete: 'address-line1',
          },
          {
            field: 'address2',
            name: 'common_addressSecond',
            type: 'text',
            value: this.Delivery.delivery.address2,
            valid: this.Delivery.validation.address2,
            validTxt: this.Delivery.validationTranslate.address2,
            maxLength: 35,
            autocomplete: 'address-line2',
          },
          {
            field: 'city',
            name: 'fbm_City',
            type: 'text',
            value: this.Delivery.delivery.city,
            valid: this.Delivery.validation.city,
            validTxt: this.Delivery.validationTranslate.city,
            autocomplete: 'address-level2',
          },
          {
            field: 'region',
            name: 'fbm_Region',
            type: 'text',
            value: this.Delivery.delivery.region,
            valid: this.Delivery.validation.region,
            validTxt: this.Delivery.validationTranslate.region,
            autocomplete: 'address-level1',
          },
          {
            field: 'zip',
            name: 'fbm_ZipCode',
            type: 'text',
            value: this.Delivery.delivery.zip,
            valid: this.Delivery.validation.zip,
            validTxt: this.Delivery.validationTranslate.zip,
            autocomplete: 'postal-code',
          },
          {
            field: 'country',
            name: 'fbm_Country',
            type: 'select',
            value: this.Delivery.delivery.country,
            valid: this.Delivery.validation.country,
            validTxt: this.Delivery.validationTranslate.country,
          },
        ]
      },

      deliveryUpdate(data) {
        this.Delivery.setAddress(this._.find(data, {field: 'address'}).value)
        this.Delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
        this.Delivery.setCity(this._.find(data, {field: 'city'}).value)
        this.Delivery.setRegion(this._.find(data, {field: 'region'}).value)
        this.Delivery.setCountry(this._.find(data, {field: 'country'}).value)
        this.Delivery.setZip(this._.find(data, {field: 'zip'}).value)

        let validate = this.Delivery.deliveryInfoValidate()

        if(!validate){
          this.setPopupDeliveryData()
          return
        }

        this.isModalChangeAddressPopup = false
        this.delivery.setAddress(this._.find(data, {field: 'address'}).value)
        this.delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
        this.delivery.setCity(this._.find(data, {field: 'city'}).value)
        this.delivery.setRegion(this._.find(data, {field: 'region'}).value)
        this.delivery.setCountry(this._.find(data, {field: 'country'}).value)
        this.delivery.setZip(this._.find(data, {field: 'zip'}).value)

        this.$emit('reload')

      },


      saveOrderData(data) {
        switch (this.typeFieldsChanges) {
          case 'personal':
            this.personalUpdate(data)
            break

          case 'delivery':
            this.deliveryUpdate(data)
            break
        }
      },


    }
  }
</script>

<style scoped lang="scss">
@import '../../../../../../../../scss/mixins/mixins';

  .user-info-admin {
    padding-bottom: 400px;

    @include for-1600 {
      padding-bottom: 100px;
    }

    @include for-1200 {
      padding-bottom: 0;
    }
  }


</style>