<template>
  <div class="fragment order-admin-products"
       v-bind:class="{'loading-products': productLoading}">
    <div class="order-create__row custom-row"
         v-if="sets.length === 0"
    >
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_createSet'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('fba_createSet.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openCreateSet"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <div class="order-create__row custom-row"
         v-if="sets.length > 0"
    >
      <template
          v-for="(set, i) in sets">

        <div class="order-create__col custom-col"
             v-if="set.data.skuSet.length > 0"
             :key="i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fba_set', 'common_edit', 'common_delete'])"></div>


          <TitleHorizontal
              :value="$t(`fba_set.localization_value.value`) + '#' + (i + 1)"
              :typeBtn="'edit'"
              :typeBtnText="$t('common_edit.localization_value.value')"
              @clickEdit="openUpdateSet(set, i)"
              :rightBtn="true"
              :rightBtnType="'delete'"
              :rightBtnText="$t('common_delete.localization_value.value')"
              @rightBtnClick="removeSet(i)"
          />

          <div class="site-table-wrap table-small"
               v-if="!isMobileFunc()"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount','fbm_ProductName', 'fba_qTy','fba_Cells'])"></div>
            <table class="site-table">
              <thead>
              <tr>
                <th style="width: 20px;" v-if="isAdmin"></th>
                <th style="width: 100px;" v-if="isAdmin">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
                <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
                <th style="width: 150px;" v-if="isAdmin">{{ $t('fba_Cells.localization_value.value') }}</th>
                <th class="text-lg-right" v-if="!isAdmin">{{ $t('fba_qTy.localization_value.value') }}</th>
                <th v-if="isAdmin"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, j) in set.data.productsSet"
                  :key="j"
              >
                <td v-if="isAdmin">
                  <ProductCheckedBlock
                          :item="item"
                          @changeProductChecked="(val) => changeProductChecked(val, item)"
                  />
                </td>
                <td v-if="isAdmin"
                  v-bind:class="{
                    'red-color' : item.data.scannedCount !== item.data.productCount,
                    'green-color' : item.data.scannedCount === item.data.productCount
                  }">
                  {{item.data.scannedCount}} / {{item.data.productCount}}
                </td>
                <td>
                  <ValueHelper
                      :value="item.data.Product"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </td>
                <td v-if="isAdmin">
                  <template v-if="cells = getCellsInTable(item)">
                    {{cells.text}}
                    <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                  </template>
                </td>
                <td v-if="!isAdmin" align="right">
                  {{item.data.productCount}}
                </td>
                <td v-if="isAdmin">
                  <div class="manage-block">
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            class="mr-3"
                            @click.native="openProductForCellPopup(i, j)"
                    />
                    <!--<LinkButton-->
                            <!--:value="'Delete'"-->
                            <!--:type="'delete'"-->
                            <!--@click.native="removeProductForCells(FBA.data.productsForCellArray, index)"-->
                    <!--/>-->
                  </div>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="site-table-mobile"
               v-if="isMobileFunc()"
          >
            <div class="site-table-mobile__inner custom-row">
              <div class="custom-col custom-col--50 custom-col--sm-100"
                   v-for="(item, index) in set.data.productsSet"
                   :key="index"
              >
                <div class="site-table-mobile__item bg--main-bg">
                  <div class="site-table-mobile__head">
                    <ProductCheckedBlock
                            v-if="isAdmin"
                            :item="item"
                            @changeProductChecked="(val) => changeProductChecked(val, item)"
                    />
                    <ValueHelper
                            :value="item.data.Product"
                            :deep="'product.productObject.name_for_fba'"
                    />
                  </div>
                  <div class="site-table-mobile__row custom-row"
                       v-if="isAdmin"
                  >
                    <div class="site-table-mobile__piece custom-col">
                      {{$t('productForCell_scannedCount.localization_value.value')}}
                      <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{item.data.scannedCount}} / {{item.data.productCount}}
                    </span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row" v-if="!isAdmin">
                    <div class="site-table-mobile__piece custom-col">
                      {{ $t('fba_qTy.localization_value.value') }}
                      <span>
                        {{item.data.productCount}}
                      </span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row"
                       v-if="isAdmin"
                  >
                    <div class="site-table-mobile__piece custom-col">
                      Cells
                      <span>
                      <template v-if="cells = getCellsInTable(item)">
                        {{cells.text}}
                        <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                      </template>
                    </span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row"
                       v-if="isAdmin"
                  >
                    <div class="site-table-mobile__piece custom-col d-flex align-items-center">
                      Manage
                      <div class="ml-2 d-flex">
                        <LinkButton
                                :value="'Edit'"
                                :type="'edit'"
                                class="mr-3"
                                @click.native="openProductForCellPopup(i, j)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="order-create__row custom-row"
         v-if="sets.length > 0"
    >
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_addSet'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('fba_addSet.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openCreateSet"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>


    <SetPopup
        v-if="isModalSetPopup"
        :FBA="FBA"
        :sets="sets"
        :user="user"
        :popupType="setPopupTypeSelected"
        :setIndex="setIndex"
        :initialOptionsProducts="initialOptionsProducts"
        @close="changeSetPopup(false)"
    />

    <!-- **** MANAGE CELLS **** -->
    <ProductForCellPopup
            v-if="isModalProductForCellPopup"
            :user="user"
            :price="false"
            :count="false"
            :description="false"
            :hsCode="false"
            :productForCellItem="sets[setIndex].data.productsSet[productInSetIndex]"
            :productsForCellArray="sets[setIndex].data.productsSet"
            :arrayIndex="productInSetIndex"
            :damaged="false"
            @close="changeProductForCellPopup(false)"
    />
  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import {FBAMixin} from "../../../../../../../mixins/FBAMixins/FBAMixin";
import PlusAccentIconSVG from "../../../../../../../../public/img/UI-group/plus-accent-icon.svg?inline";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import SetPopup from "../../../../popups/SetPopup/SetPopup";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ProductForCellPopup from "../../../../../../coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import {productForCellMixin} from "../../../../../../../mixins/productMixins/productForCellMixin";
import ProductCheckedBlock from "../ProductCheckedBlock/ProductCheckedBlock";


export default {
  name: "SetBlock",
  components: {
    ProductCheckedBlock,
    ProductForCellPopup,
    LinkButton,
    PlusAccentIconSVG,
    MainButton,
    TitleHorizontal,
    SetPopup,
    ValueHelper,
  },

  mixins: [
    mixinDetictingMobile,
    FBAMixin,
    productForCellMixin,
  ],

  props: {
    FBA: Object,
    sets: Array,
    user: Object,
    initialOptionsProducts: Array,
    productLoading: Boolean,
  },

  data(){
    return{
      isModalSetPopup: false,
      isModalProductForCellPopup: false,

      setPopupType:{
        create: {
          id: 1,
          name: 'create'
        },
        update: {
          id: 2,
          name: 'update'
        },
        add: {
          id: 3,
          name: 'add'
        },
      },

      setPopupTypeSelected: {},

      setIndex: -1,
      productInSetIndex: -1,
    }
  },

  methods: {

    changeProductForCellPopup(open){
      this.isModalProductForCellPopup = open
      if(!open){
        this.$emit('recalculateFees')
      }
    },

    // openProductForCellPopup(setIndex = false, productInSetIndex = false){
    //   // this.FBA.data.ProductForCell.data.Product.setProduct(item)
    //   console.log(this.FBA.data.sets[setIndex]);
    //   if(setIndex >= 0){
    //     this.setIndex = setIndex
    //     this.productInSetIndex = productInSetIndex
    //   } else {
    //     this.setIndex = -1
    //     this.productInSetIndex = -1
    //   }
    //   this.changeProductForCellPopup(true)
    // },

    changeSetPopup(open){
      this.isModalSetPopup = open
      if(!open){
        this.$emit('recalculateFees')
      }
    },

    openCreateSet(){
      this.setPopupTypeSelected = this.setPopupType.create
      this.setIndex = -1
      this.changeSetPopup(true)
    },

    openUpdateSet(set, index){
      this.setIndex = index
      // this.FBA.setSet(set)
      this.FBA.setSetDefault(set)
      this.setPopupTypeSelected = this.setPopupType.update
      this.changeSetPopup(true)
    },

    removeSet(index){
      let text = {
        title: 'fba_deleteSet',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        this.FBA.removeSetFromSets(index)
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/colors";

  .order-admin-products{
    .red-color{
      color: $red
    }
    .green-color{
      color: $green
    }
  }
  .loading-products{
    opacity: 0.5;
    pointer-events: none;
  }
</style>
