<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fba_UserLabelType'])"></div>
      {{ $t('fba_UserLabelType.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_iHasLabel', 'fba_warehouseBuyLabel'])"></div>
        <LargeSwitch
            :activeValue="FBA.data.userLabelTypes.iHasLabel"
            :inactiveValue="FBA.data.userLabelTypes.warehouseBuyLabel"
            :labelActiveValue="$t('fba_iHasLabel.localization_value.value')"
            :labelInactiveValue="$t('fba_warehouseBuyLabel.localization_value.value')"
            :checkedItem="FBA.data.userLabelType"
            :name="'orderCreateSwitch'"
            v-bind:class="{
              'disable-large-switch': editAdmin
            }"
            @change="(val) => userLabelTypeChange(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LargeSwitch from "../../../../../../UI/radiobuttons/LargeSwitch/LargeSwitch";
import {ORDER_INBOUND_TYPE} from "../../../../../../../staticData/staticVariables";

export default {
  name: "UserLabelTypeBlock",
  components: {
    LargeSwitch,
  },
  props: {
    FBA: Object,
    editAdmin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
    }
  },

  methods: {
    userLabelTypeChange(val) {
      this.FBA.setUserLabelType(val)
      this.$emit('typeReturnChanged')
    },
  }
}
</script>

<style scoped>

</style>
