<template>
  <AccordBlock>
    <template slot="header">
      Order Details
    </template>

    <template slot="body">
      <ShippingFBA
          v-if="FBA.data.shippingCompanies.length > 0"
          :FBA="FBA"
          :shippingCompanyData="FBA.data.shippingCompanies"
          :isAdminManage="true"
          @reCreateLabel="$emit('reCreateLabel')"
          @reload="$emit('reload')"
      />


    </template>
  </AccordBlock>
</template>

<script>
  import ShippingFBA from "@/components/modules/OrdersFBAModule/components/components/chunks/ShippingFBA/ShippingFBA";
  import {FBAMixin} from "@/mixins/FBAMixins/FBAMixin";
  import AccordBlock from "@/components/coreComponents/AccordBlock/AccordBlock";


  export default {
    name: "OrderDetails",

    components: {
      ShippingFBA,
      AccordBlock,
    },

    mixins: [FBAMixin],

    props: {
      FBA: Object,
    },


    watch: {
      /**
       * If change or choose Country
       */
      selectedCountry() {
        this.getShippingData().then(() => {
          if (this.currentPersonalContactName && this.selectedCountry && this.FBM.data.useEasyPost) {
            this.getShippingPrices(true)
          }
        })
      },
    },

    data() {
      return {

      }
    },

    mounted() {
      this.checkShippingCompany()
      console.log(45654);
    },

    methods: {

    }
  }
</script>

<style scoped>
  .toggle-block.small-size .toggle-block__type{
    margin-right: 0px;
  }

  .toggle-block.small-size .toggle-block__details {
    max-width: 100%;
    min-height: 310px;
    width: 100%;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
    }
  }

  /*@media (max-width: 992px){*/
    /*.toggle-block__details{*/
      /*margin-top: 20px;*/
    /*}*/
  /*}*/

  @media (max-width: 1200px){
    .toggle-block.small-size .toggle-block__details {
      min-height: auto;
    }
  }



  .min-w-140{
    min-width: 140px;
  }

  .fsz11{
    font-size: 11px;
    color: #232323;
  }

</style>