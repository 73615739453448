<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([popupTitle])"></div>
        {{$t(`${popupTitle}.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">

          <template v-for="(item, index) in dataItems">
            <div class="custom-col  custom-col--md-100"  :key="index"
                 v-bind:class="{
                  'custom-col--50': dataItems.length > 1 && item.type !== 'radio',
                  'd-flex': item.type === 'radio',
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                 }">
              <!--<div class="admin-edit" @click="editTranslate([item.name])"></div>-->
              <template v-if="(item.type === 'text' && item.field !== 'region') || (!showRegionsSelect && item.field === 'region' && item.type === 'text')">
                <DefaultInput
                    :label="$t(`${item.name}.localization_value.value`)"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :type="item.type"
                    :maxLength="item.maxLength ? item.maxLength : ''"
                    :caption="item.maxLength ? $t('express_maxSymbols.localization_value.value') : ''"
                    :autocomplete="_.has(item, 'autocomplete') ? item.autocomplete : true"
                    v-model="item.value"
                />
              </template>

              <template v-if="showRegionsSelect && item.field === 'region' && optionsRegions.length > 0">
                <DefaultSelect
                    :options="optionsRegions"
                    :label="$t(`${item.name}.localization_value.value`)"
                    :optionsLabel="'code'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="getSelectedRegion(item)"
                    @change="val => {changeRegion(val, index)}"
                />
              </template>

              <template v-if="item.field === 'country'">
                <DefaultSelect
                    :options="options"
                    :label="$t(`${item.name}.localization_value.value`)"
                    :optionsLabel="'label'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="item.value"
                    @change="val => {changeCountry(val, index)}"
                />
              </template>

              <template v-if="item.type === 'textarea'">
                <TextareaDefault
                        :label="$t(`${item.name}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': item.valid}"
                        :error="item.valid"
                        :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                        v-model="item.value"
                />
              </template>

              <template v-if="item.type === 'radio' && item.field === 'packaging'">
                <div class="custom-row">
                  <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc">
                    <RadioDefault
                            :label="'None'"
                            name="fedexRadioList2"
                            :value="true"
                            @input="changeGiftPaper('', index)"
                    />
                  </div>
                </div>

              </template>
            </div>
            <template v-if="item.field === 'country'">
              <div class="custom-col  custom-col--md-100"  :key="index+400"
                   v-bind:class="{
                  'custom-col--50': dataItems.length > 1 && item.type !== 'radio',
                 }">
              </div>
            </template>
          </template>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="$emit('save', items)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../services/filterCountries";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {
    AUSTRALIA_ID,
    CANADA_COUNTRY_ID,
    MEXICO_COUNTRY_ID
  } from "../../../../../staticData/staticVariables";
  import {USA_COUNTRY_ID} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChangeDataPopup",

    props: {
      items: Array,
      popupTitle: String,
      getRegionsIfUSA: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      RadioDefault,
      DefaultSelect,
      TextareaDefault,
      Modal,
      MainButton,
      DefaultInput,
    },

    data() {
      return {
        dataItems: this.items,
        options: [],
        optionsRegions: [],
        showRegionsSelect: false,

      }
    },

    watch: {
      items(newVal) {
        this.dataItems = newVal
      }
    },

    mounted(){
      if(this._.find(this.dataItems, {type: 'select'})){
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          filterCountriesUSA(countries)
          this.options = countries;
        })
      }

      if(this.getRegionsIfUSA && this._.find(this.dataItems, {field: 'region'}) && this._.find(this.dataItems, {field: 'country'})
          && (this._.find(this.dataItems, {field: 'country'})?.value?.id === USA_COUNTRY_ID || this._.find(this.dataItems, {field: 'country'})?.value?.id === AUSTRALIA_ID ||
              this._.find(this.dataItems, {field: 'country'})?.value?.id === CANADA_COUNTRY_ID || this._.find(this.dataItems, {field: 'country'})?.value?.id === MEXICO_COUNTRY_ID)){
        this.initRegionsSelectIfSelectedCountries(this._.find(this.dataItems, {field: 'country'})?.value?.id)
        this.showRegionsSelect = true
      }

    },

    methods: {
      changeCountry(val, index) {
        this.dataItems[index].value = val
        if (this.getRegionsIfUSA && (val?.id === USA_COUNTRY_ID || val?.id === AUSTRALIA_ID || val?.id === CANADA_COUNTRY_ID || val?.id === MEXICO_COUNTRY_ID)) {
          this.initRegionsSelectIfSelectedCountries(val?.id)
          this.showRegionsSelect = true
        }
        else {
          this.showRegionsSelect = false
        }
      },

      initRegionsSelectIfSelectedCountries(countryId) {
        this.$store.dispatch('getRegionsByCountry', countryId).then(() => {
          this.optionsRegions = this._.toArray(this.$store.getters.getRegions)
        });
      },

      getSelectedRegion(item) {
        return this.optionsRegions.find(searchItem => {
          return searchItem?.code.toLowerCase() === item.value.toLowerCase()
        })
      },

      changeRegion(val, index) {
        this.dataItems[index].value = val.code
      },

    }
  }
</script>

<style scoped>

</style>
