<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getOrdersFBA.length > 0 && !$store.getters.getOrdersFBALoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'fba_orderId',
          'fba_created',
          'fba_shipped',
          'fba_trackingNumber',
          'fba_comment',
          'fba_orderStatus',
          'fba_set',
          'fba_setCost',
          'fba_shippingCost',
          'common_manage',
          'common_edit',
          'common_delete',
          'common_copy',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('fba_orderId.localization_value.value') }}</th>
            <th>{{ $t('fba_created.localization_value.value') }}</th>
            <th>{{ $t('fba_shipped.localization_value.value') }}</th>
            <th v-if="isAdmin">{{ $t('common_user.localization_value.value') }}</th>
            <th>{{ $t('fba_comment.localization_value.value') }}</th>
            <th>{{ $t('fba_trackingNumber.localization_value.value') }}</th>
            <th>{{ $t('fba_orderStatus.localization_value.value') }}</th>
            <th v-if="!isAdmin">{{ $t('fba_set.localization_value.value') }}</th>
            <th>{{ $t('fba_setCost.localization_value.value') }}</th>
            <th>{{ $t('fba_shippingCost.localization_value.value') }}</th>
            <th width="100%"></th>
            <th style="padding-right: 10px">{{ $t('common_manage.localization_value.value') }}</th>
          </tr>

          </thead>
          <tbody>
          <tr v-for="(item, index) in $store.getters.getOrdersFBA"
              :key="index"
              class="transition"
              :class="{'site-table__gray-row' : $store.getters.getOrderFBAItemId === item.id}"
          >
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :dataValue="item.id"
                  :selectedNow="selectedNow"
              />
            </td>
            <td>
              <router-link
                  :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + item.id"
                  class="table-link btn-style">
                <ValueHelper
                    :value="item['id']"
                />
              </router-link>
            </td>
            <td>
              <ValueHelper
                  :value="item['created_at'] | moment('DD MMM, YYYY')"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item['delivery_date'] | moment('DD MMM, YYYY')"
              />
            </td>

            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <TooltipTextHelper
                  class="comment-row"
                  :text="item['comment']"
                  :paragraph="item['comment']"
                  :styleClass="'white-space-normal style-ellipsis'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item['tracking_number']"
              />
            </td>
            <td>
              <div class="site-status">
                <template v-if="!isStatusCompleted(item)">
                  <StatusIcoBtn :type="'in-progress'"/>
                </template>
                <template v-if="isStatusCompleted(item)">
                  <StatusIcoBtn :type="'delivered'"/>
                </template>
                <ValueHelper
                    :value="$t(`${_.find(FBA_ORDER_STATUS, {value: item['status']}).translation}.localization_value.value`)"
                />
              </div>
            </td>
            <td align="right" v-if="!isAdmin">
              <ValueHelper
                :value="item['count_sets']"
              />
            </td>
            <td align="right">
              <ValueHelper
                  :value="item['amazon_sets_cost']"
              />
            </td>
            <td align="right">
              <ValueHelper
                  :value="item['shipping_cost']"
              />
            </td>
            <td width="100%"></td>
            <td>
              <div class="table-right table-manage-list ">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton"
                               class="w250"
                >

                  <template slot="item" v-if="isAdmin || !isStatusCompleted(item)">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.ordersFBA + '/' + item.id"
                       :disabled="$store.getters.ordersFBABtn"
                    />
                  </template>
                  <template slot="item" v-if="!isStatusCompleted(item)">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeItem(item['id'])"
                       :disabled="$store.getters.ordersFBABtn"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">

            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersFBA"
                 :key="index"
            >
              <OrdersFBATableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  @removeItem="removeItem(item.id)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getOrdersFBACommonList.next_page_url !== null && $store.getters.getOrdersFBA.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersFBAPage}"
              :count="$store.getters.getOrdersFBACommonList.total - $store.getters.getOrdersFBAForPage * $store.getters.getOrdersFBACommonList.current_page < $store.getters.getOrdersFBAForPage ?
                  $store.getters.getOrdersFBACommonList.total - $store.getters.getOrdersFBAForPage * $store.getters.getOrdersFBACommonList.current_page:
                  $store.getters.getOrdersFBAForPage"
          />
          <ExportBtn
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getOrdersFBALoading === false && $store.getters.getOrdersFBA.length === 0">
      <NoResult
          class="pt-4"
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
// import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import OrdersFBATableMobile from "./OrdersFBATableMobile/OrdersFBATableMobile";
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
import {FBAMixin} from "../../../../../../../mixins/FBAMixins/FBAMixin";
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import {FBA_ORDER_STATUS, FBA_STATUS_COMPLETED} from "../../../../../../../staticData/staticVariables";
// import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
// import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";


export default {
  name: "OrdersFBATable",

  components: {
    StatusIcoBtn,
    DefaultCheckbox,
    TableUserColumn,
    TooltipTextHelper,
    // TooltipBtn,
    // MainButton,
    // DefaultInput,
    ShowMore,
    ExportBtn,
    LinkButton,
    ManagerButton,
    // ProductsToWarehouse,
    OrdersFBATableMobile,
    NoResult,
    ValueHelper,
  },

  mixins: [mixinDetictingMobile, FBAMixin],

  props: {
    countFilterParams: Number,
    selectedNow: Boolean,
  },

  data() {
    return {
      orderId: '',
      showFilter: false,
      FBA_ORDER_STATUS: FBA_ORDER_STATUS,
      FBA_STATUS_COMPLETED: FBA_STATUS_COMPLETED,
      hideEditField: false,
    }
  },

  methods: {
    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    cantDeleteProduct() {
      let text = {
        title: 'Ooops',
        txt: 'Sorry, you cannot uninstall this product',
        no: 'Ok'
      }

      let confirm = () => {
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    removeProduct() {
      let text = {
        title: 'Delete Product?',
        txt: 'Are you sure you want to Delete Product?',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        // this.$store.dispatch('deleteShopPermission', id).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     let shops = this.$store.getters.getShops
        //     shops.map((item, index) => {
        //       if(item.id === id) {
        //         shops[index].shop_oauth_param.key_and_token_exist = 0
        //       }
        //     })
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('success', 'Произошла ошибка')
        //     }, 200)
        //   }
        // })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    }
  }

}

</script>

<style scoped>
.comment-row {
  max-width: 350px;
  min-width: 150px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1600px) {
  .comment-row {
    width: 250px;
    min-width: 100px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 200px;
  }
}

.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

.w250 {
  width: 250px;
}
</style>
